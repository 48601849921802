import styled from 'styled-components';
import VideoBanner from "./../../assets/video/video_banner.mp4"

const StyledDiv= styled.div`
    .main_background{
        width: 100%;
        height: auto;
        position: relative;
        background-color: black;
    }
    .main_banner{
        position: relative;
        z-index: 3;
        width: 100%;
        height: auto;
    }
    .main_mask{
        position: absolute;
        color: white;
        width: 100%;
        height: 100%;
        z-index: 10;
        background: linear-gradient(to bottom, 
                                    rgba(0, 0, 0, 1) 0%, 
                                    rgba(0, 0, 0, 0) 10%, 
                                    rgba(0, 0, 0, 0) 70%, 
                                    rgba(0, 0, 0, 1) 100% );
    }
`;

const ComponentsMainLogo = () => {

    return (
        <StyledDiv>
            <div className='main_background'>
                <div className='main_mask'/>
                <video className='main_banner' muted autoPlay="autoPlay" loop="loop">
                    <source src={ VideoBanner } type="video/mp4"/>
                </video>
            </div>
        </StyledDiv>
    )

}

export default ComponentsMainLogo;