import Layout from "../components/Layout";

const Aboutpage = () => {
    return (
        <Layout>
            <div>This is About Page</div>
        </Layout>
    )
}

export default Aboutpage;