import { contractTransferFromAddress, contractTransferFromABI, contractTransferFromGoerliAddress } from "../configs/contractTransferFrom"
import { createContext } from "react";
import { chain } from "wagmi";

const ContextContractTransferFrom = createContext( {
    addressOrName: contractTransferFromAddress,
    contractInterface: contractTransferFromABI,
    chainName: "mainnet",
    chainID: chain.mainnet.id,
});

export default ContextContractTransferFrom;