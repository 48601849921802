import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import ImageTeam01 from './../../../assets/images/team/team_01.png'
import ImageTeam02 from './../../../assets/images/team/team_02.png'
import ImageTeam03 from './../../../assets/images/team/team_03.png'
import ImageTeam04 from './../../../assets/images/team/team_04.png'
import ImageTeam05 from './../../../assets/images/team/team_05.png'
import ImageTeam06 from './../../../assets/images/team/team_06.png'
import ImageTeam07 from './../../../assets/images/team/team_07.png'
import ImageTeam08 from './../../../assets/images/team/team_08.png'
import ImageTeam09 from './../../../assets/images/team/team_09.png'
import ImageTeam10 from './../../../assets/images/team/team_10.png'
import ImageTeam11 from './../../../assets/images/team/team_11.png'
import ImageTeam12 from './../../../assets/images/team/team_12.png'


const StyledDiv= styled.div`
    .team_card{
        padding-top: 50px;
        padding-bottom: 50px;
        padding-left: 300px;
        padding-right: 300px;
        color: white;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        align-content: space-around;
        width: 100%;

        @media screen and (max-width: 1500px) {
            padding-left: 150px;
            padding-right: 150px;
        }
    
        @media screen and (max-width: 1200px) {
            padding-left: 100px;
            padding-right: 100px;
        }
    
        @media screen and (max-width: 992px) {
            padding-left: 10px;
            padding-right: 10px;
        }

        @media screen and (max-width: 576px){
            padding-left: 5px;
            padding-right: 5px;
        }

    }

    .team_card_image_size{
        width: 200px;
        height: 200px;
    }
    .team_card_user_title{
        font-size: 14px;
        text-align: center;
        padding-bottom : 15px;
    }
    .team_card_user_name{
        font-size: 20px;
        text-align: center;
    }

`;

const TeamCard = ( { imageURL, userTitle, userName } ) => {

    const { t } = useTranslation(); 

    return ( <div className="team_card_bg">
        <img className='team_card_image_size' src={ imageURL } />
        <div className='team_card_user_name'>{ t(userName) }</div>
        <div className='team_card_user_title'>{ t(userTitle) }</div>
    </div> )
}

const TeamExtend = () => {

    const oriTeamData = [
        { imageURL: ImageTeam01, memberTitle: "team_member.title_01", memberName: "team_member.name_01" },
        { imageURL: ImageTeam02, memberTitle: "team_member.title_02", memberName: "team_member.name_02" },
        { imageURL: ImageTeam11, memberTitle: "team_member.title_11", memberName: "team_member.name_11" },
        { imageURL: ImageTeam12, memberTitle: "team_member.title_12", memberName: "team_member.name_12" },
        { imageURL: ImageTeam03, memberTitle: "team_member.title_03", memberName: "team_member.name_03" },
        { imageURL: ImageTeam04, memberTitle: "team_member.title_04", memberName: "team_member.name_04" },
        { imageURL: ImageTeam06, memberTitle: "team_member.title_06", memberName: "team_member.name_06" },
        { imageURL: ImageTeam07, memberTitle: "team_member.title_07", memberName: "team_member.name_07" },
        { imageURL: ImageTeam08, memberTitle: "team_member.title_08", memberName: "team_member.name_08" },
        { imageURL: ImageTeam09, memberTitle: "team_member.title_09", memberName: "team_member.name_09" },
        { imageURL: ImageTeam10, memberTitle: "team_member.title_10", memberName: "team_member.name_10" },
        { imageURL: ImageTeam05, memberTitle: "team_member.title_05", memberName: "team_member.name_05" }
    ];

    const [ teamData, setTeamData ] = useState( oriTeamData );

    return ( 
    <StyledDiv>
        <div className='team_card'>
        {
            teamData.map( ( item, idx ) => {
                return <TeamCard key={idx}
                                imageURL={ item.imageURL } 
                                userTitle={ item.memberTitle } 
                                userName={ item.memberName } 
                />
            } )
        }
        </div>
    </StyledDiv> )
}

export default TeamExtend;