import styled from 'styled-components';
import FAQExtend from './extend/faq_extend';
import ImageFAQ from './../../assets/images/FAQ/page_faq.png';
import LayoutImageTitle from '../../components/LayoutImageTitle';
import ImageTitle from './../../assets/images/FAQ/title.png';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTranslation } from 'react-i18next';

const StyledDiv= styled.div`

  .faq_information_image{
    padding-right: 15%;

    img{
      width: 100%;
      height: auto;
      position: relative;
    }

    @media screen and (max-width: 992px) {
      padding-right: 0%;
    }

  }

  .components_faq_title_image{
    position: relative;
    width: 295px;
    padding-top: 5vh;
    left: 12vw;

    img{
      position: relative;
      width: 195px;
      height: 113px;
      z-index: 20;
    }

    div{
      position: relative;
      top: -30px;
      height: 30px;
      width: 295px;
      background: linear-gradient( -35deg, transparent 50px, #444444 0) top right;
      background-repeat: no-repeat;
      z-index: 10;

      @media screen and (max-width: 768px) {
        visibility: hidden;
      }

    }

    @media screen and (max-width: 1400px) {
      left: 6vw;
    }
  
    @media screen and (max-width: 992px) {
      left: 10vw;
    }
    @media screen and (max-width: 768px) {
      left: 0vw;
      width: 100%;
      text-align: center;
    }

  }

  .components_faq_title_info{
    padding-left: 20%;

    @media screen and (max-width: 1400px) {
      padding-left: 11%;
    }
  
    @media screen and (max-width: 992px) {
      padding-left: 5%;
    }
  }

  .components_faq_info_sub{
    position: relative;
    font-size: 14px;
    padding-left: 12vw;
    padding-right: 12vw;
    z-index: 50;

    @media screen and (max-width: 1400px) {
      padding-left: 8vw;
      padding-right: 8vw;
    }
  
    @media screen and (max-width: 992px) {
      padding-left: 5vw;
    }

    @media screen and (max-width: 576px) {
      padding-left: 1vw;
    }
  }

`;

const ComponentsFAQ = () => {

    const { t } = useTranslation();

    return (
        <StyledDiv>
          <div id='PAGE_FAQ' style={ {backgroundColor: "black", color: "white"} }>
            <Row style={ { width: "100%" } }>
              <Col lg>
                <div className='components_faq_title_image'>
                  <img src={ ImageTitle }/>
                  <div/>
                </div>
                <div className='components_faq_title_info'>
                  <FAQExtend/>
                </div>
              </Col>
              <Col>
                  <div className='faq_information_image'> <img src={ImageFAQ}/> </div>
              </Col>
            </Row>
            <div className='components_faq_info_sub'>
              <div>{ t( "faq_information.support" ) }</div>
            </div>
          </div>
        </StyledDiv>
    )
}

export default ComponentsFAQ;