import styled from 'styled-components';
import LayoutImageTitle from '../../components/LayoutImageTitle';
import TeamExtend from './extend/team_extend';

import ImageTitle from "./../../assets/images/team/title.png"

const StyledDiv= styled.div`

  .components_team_title_image{
    position: relative;
    width: 377px;
    padding-top: 5vh;
    left: 12vw;

    img{
      position: relative;
      width: 277px;
      height: 113px;
      z-index: 20;
    }

    div{
      position: relative;
      top: -30px;
      height: 30px;
      width: 377px;
      background: linear-gradient( -35deg, transparent 50px, #444444 0) top right;
      background-repeat: no-repeat;
      z-index: 10;

      @media screen and (max-width: 768px) {
        visibility: hidden;
        width: 277px;
      }
    }

    @media screen and (max-width: 1400px) {
      padding-top: 2vh;
      left: 6vw;
    }

    @media screen and (max-width: 992px) {
      left: 10vw;
    }
    
    @media screen and (max-width: 768px) {
      left: 0vw;
      width: 100%;
      text-align: center;
    }

  }

`;

const ComponentsTeam = () => {

    return (
        <StyledDiv>
          <div id='PAGE_TEAM' style={ { backgroundColor: "black", color: "white" } }>
            <div className='components_team_title_image'>
              <img src={ ImageTitle }/>
              <div/>
            </div>
            <div>
              <TeamExtend/>
            </div>
          </div>
        </StyledDiv>
    )
}

export default ComponentsTeam;