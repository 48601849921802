import styled from 'styled-components';

import ImageQ from "./../../../assets/images/icon_q.png";
import ImageA from "./../../../assets/images/icon_a.png";

import { useTranslation } from 'react-i18next';
import { useState } from 'react';

const StyledDiv= styled.div`
    .faq_extend{
    }
    .faq_main{
        padding-left: 4vw;
    }

    .faq_item{
        position: relative;
        font-size: 20px;
        padding-top: 45px;

        div p{
            padding-top: 5px;
            padding-left: 10px;
        }

        div img{
            width : 18px;
            height: 32px;
        }
        div{
            display: flex;
            position: relative;
        }
    }

    @media screen and (max-width: 1440px) {
        .media_faq_main{
            padding-left: 2vw;
        }
        .media_faq_item{
            padding-top: 20px;
        }
    }

`;

const FAQExtend = () => {

    const { t } = useTranslation();

    const oriFAQData = [
        { question: "faq_information.q1", answer: "faq_information.a1" },
        { question: "faq_information.q2", answer: "faq_information.a2" },
        { question: "faq_information.q3", answer: "faq_information.a3" },
        { question: "faq_information.q4", answer: "faq_information.a4" },
    ];

    const [ FAQData, setFAQData ] = useState( oriFAQData );

    return (
        <StyledDiv>
            <div className='faq_extend'>
                <div className='faq_main media_faq_main' >
                {
                    FAQData.map( (item, idx) => {
                        return ( 
                        <div className='faq_item media_faq_item' key={ idx }>
                            <div><img src={ ImageQ }/><p>{ t(item.question) }</p></div>
                            <div><img src={ ImageA }/><p>{ t(item.answer) }</p></div>
                        </div> )
                    } )
                }
                </div>
            </div>
        </StyledDiv>
    )
}

export default FAQExtend;