import { jsonRpcProvider } from 'wagmi/providers/jsonRpc'
import {
  defaultChains,
  chain,
  createClient,
  configureChains,
  useNetwork,
} from "wagmi";
import { publicProvider } from "wagmi/providers/public";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import { infuraProvider } from "wagmi/providers/infura";

const INFURA_ID = process.env.INFURA_ID;

const { chains, provider, webSocketProvider } = configureChains(
  [ chain.mainnet ],
  [
    infuraProvider( { INFURA_ID } ),
  ],
)

const client = createClient({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({ chains: [ chain.mainnet ] }),
  ],
  provider,
});

export default client;