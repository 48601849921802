import styled from 'styled-components';
import ImageFooter from './../assets/images/foot/footer_logo.png'

const StyledDiv= styled.div`
    .footer_bg{
        width: 100%;
        position: relative;
        background-color: black;
    }
    .footer_main{
        position: relative;
        color: white;
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;

        padding-left: 30px;
        padding-right: 30px;

        @media screen and (max-width: 768px) {
            flex-wrap: wrap;
        }
    }

    .footer_image{
        height: 60px;
        width: auto;
        margin-top: 30px;
        margin-bottom: 30px;

        img{
            height: 100%;
            width: auto;
        }

        @media screen and (max-width: 768px) {
            margin-top: 5px;
            margin-bottom: 5px;
        }
    }
    .footer_copyright{
        position: relative;
    }

    
`;

const Footer = () => {

    const strCopyRight = "All images, content & intellectual materials are copyright © 2022 Model Media All rights Reserved";

    return (
        <StyledDiv>
            <div className='footer_bg'>
                <div className='footer_main'>
                    <div className='footer_image'>
                        <img src={ ImageFooter }/>
                    </div>
                    <div className='footer_copyright'>{ strCopyRight }</div>
                </div>
            </div>
        </StyledDiv>
    )
}

export default Footer;