import styled from 'styled-components';
import RoadMapExtend from './extend/roadmap_extend';
import ImageRoadMap from './../../assets/images/roadmap/page_roadmap.png';
import ImageTitle from './../../assets/images/roadmap/title.png';
import LayoutImageTitle from '../../components/LayoutImageTitle';
import VideoMp4 from './../../assets/video/video_roadmap.mp4';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const StyledDiv= styled.div`
  .components_roadmap_infomation{
    color: white;
    width: 100%;
    padding-left: 15%;
    padding-right: 15%;
  }

  .components_roadmap_title{
    position: relative;
    max-width: 587px;
    z-index: 80;
    left: 12vw;
    padding-top: 5vh;

    img{
      position: relative;
      width: 487px;
      height: 112px;
      z-index: 20;

      @media screen and (max-width: 576px) {
        width: 100%;
        height: auto;
      }
    }
    
    div{
      position: relative;
      top: -30px;
      height: 30px;
      width: 587px;
      background: linear-gradient( -35deg, transparent 50px, #444444 0) top right;
      background-repeat: no-repeat;
      z-index: 10;
      @media screen and (max-width: 768px) {
        visibility: hidden;
        width: 320px;
      }
    }

    @media screen and (max-width: 1400px) {
      left: 6vw;
    }

    @media screen and (max-width: 992px) {
      left: 10vw;
    }

    @media screen and (max-width: 576px) {
      left: 0vw;
    }

  }

  .roadmap_information_image{
    position: relative;
    video{
      padding: 2px;
      width: 100%;
      height: auto;
    }
  }
  .components_roadmap_extend{
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-60%);
  }

`;

const ComponentsRoadMap = () => {

    return (
        <StyledDiv>
          <div id="PAGE_ROADMAP" style={ {backgroundColor: 'black', color: "white"} }>
            <div className='components_roadmap_title'>
              <img src={ ImageTitle }/>
              <div/>
            </div>
            <Row className='components_roadmap_infomation'>
              <Col lg>
                <div className='roadmap_information_image'>
                  <video muted autoPlay="autoplay" loop="loop">
                    <source src={ VideoMp4 } type="video/mp4"/>
                  </video>
                </div>
              </Col>
              <Col>
                <div className='components_roadmap_extend'>
                  <RoadMapExtend />
                </div>
              </Col>
            </Row>
          </div>
        </StyledDiv>
    )
}

export default ComponentsRoadMap;