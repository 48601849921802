import styled from 'styled-components';
import ImageLogoMEV from "./../../../assets/images/logo_mev.png";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ImageTitle from './../../../assets/images/FAQ/page_faq.png';
import { useTranslation } from 'react-i18next';


const StyledDiv= styled.div`
  .comp_about{
    position: relative;
    z-index: 3;
    color: white;
    height: 1080px;
  }
  
  .about_info{
    padding-left: 200px;
    padding-right: 100px;
  }

  .info_logo{
    padding : 5px;
    text-align: center;
  }

  .info_logo_title{
    position: relative;
    padding-bottom : 30px;
    text-align: center;
    height: 15vh;
  }
  .info_logo_title_image{
    height: 100%;
    width: auto;
  }

  .info_hime_row{
    padding_top: 10px;
  }

  .info_hime_col_1{
    display: flex;
    justify-content: center;
    align-item: center;
    padding-right: 20px;
  }
  .info_hime_col_2{
    display: flex;
    align-item: center;
  }

  .info_hime_image{
    position: relative;
    width: 100%;
    height: auto;
    text-align: center;
    padding: 50px;
  }

  .info_hime{
    padding-top : 100px;
    padding-bottom : 100px;
  }
  .info_hime_title{
    font-size: 54px;
    padding-bottom : 30px;
    
  }
  .info_logo_title_extend{
    font-size: 16px;
    div{
      padding-bottom: 10px;
    }
  }

  @media screen and (max-width: 992px) {
    .media_layout_info{
      text-align: center;
    }
  }

  @media screen and (max-width: 1200px) {
    .media_layout_info_padding{
      padding-left: 100px;
      padding-right: 100px;
    }
  }

`;

const AboutExtend = () =>{

  const { t } = useTranslation();

    return (
        <StyledDiv>
            <div className='about_info media_layout_info media_layout_info_padding'>
              <div className='info_logo'>
                <div className='info_logo_title' ><img className='info_logo_title_image' src={ ImageLogoMEV }/></div>
                  <div className='info_logo_title_extend'>
                    <div>{ t( "mev_information.message_01" ) }</div>
                    <div>{ t( "mev_information.message_02" ) }</div>
                    <div>{ t( "mev_information.message_03" ) }</div>
                    <div>{ t( "mev_information.message_04" ) }</div>
                    <div>{ t( "mev_information.message_05" ) }</div>
                  </div>
              </div>
              <Row className='info_hime_row'>
                <Col className='info_hime_col_1' lg>
                  <img className='info_hime_image' src={ ImageTitle }/>
                </Col>
                <Col className='info_hime_col_2'>
                  <div className='info_hime'>
                        <div className='info_hime_title'>MEV Hime (姬)</div>
                        <div className='info_logo_title_extend'>
                            <div>Model Eros Village-Hime(姬)是以日本神話中的天鈿女命為主旨，</div>
                            <div>由4位知名日本女星為原型，總數為3888的藝術品NFT。</div>
                            <div>天鈿女命在神話中以半裸身體體現女性美的舞蹈，</div>
                            <div>並吸引天照大神使世間重現光明之事蹟被認為是日本舞蹈的起源。</div>
                            <div>MEV作品中的女星們就像天鈿女命那般，</div>
                            <div>以大膽奔放的舞姿和女性柔美的象徵去吸引人們最原始的慾望.</div>
                        </div>
                    </div>
                </Col>
              </Row>
            </div>
        </StyledDiv>
        
    )
}

export default AboutExtend;