export const LanguageTW =
{
    about: "關於",
    mev: "Model Eros Village",
    mev_information: { message_01: "MEV致力於設計、開發各式區塊鏈技術，與麻豆集團本身所開發的各類產品進行互動，帶給區塊鏈世界一份不同的色彩，創造全新的體驗，帶領麻豆於Web2裡的千萬名支持者，攜手跨進區塊鏈世界，讓Web3世界因MEV更加豐富多元。",
                        message_02: "",
                        message_03: "",
                        message_04: "",
                        message_05: ""},
    hime: "MEV Hime (姬)",
    hime_information: { message_01: "Model Eros Village-Hime(姬)是以日本神話中的天鈿女命為主旨，由4位知名日本女星為原型，總數為3888的藝術品NFT。",
                        message_02: "天鈿女命在神話中以半裸身體體現女性美的舞蹈，並吸引天照大神使世間重現光明之事蹟被認為是日本舞蹈的起源。",
                        message_03: "MEV作品中的女星們就像天鈿女命那般，以大膽奔放的舞姿和女性柔美的象徵去吸引人們最原始的慾望。",
                        message_04: "",
                        message_05: "",
                        message_06: "" },
    utility: "效用",
    utility_information_01: { subject: "擁有你的NFT",
                            title_01: "戰士任務 1",
                            message_01: "鑄造NFT取得麻豆TV永久會員。",
                            title_02: "戰士任務 2",
                            message_02: "持有NFT解鎖麻豆TV分潤賦能。",
                            title_03: "戰士任務 3",
                            message_03: "參與活動空投升級你的NFT。" },
    utility_information_02: { subject: "麻豆元宇宙建構",
                            title_01: "村落任務 1",
                            message_01: "虛擬世界VR計畫啟動，參與腳本製作與女優徵選。",
                            title_02: "村落任務 2",
                            message_02: "帶著你的專屬NFT參加3天2夜麻豆村全球粉絲見面會。",
                            title_03: "村落任務 3",
                            message_03: "麻豆VR上線，解鎖分潤與永久會員賦能。" },
    utility_information_03: { subject: "麻豆情趣娛樂村",
                            title_01: "元宇宙任務",
                            message_01: "參與麻豆元宇宙情趣娛樂村、電影院、成人旅遊介紹、與社區建設。" },
    own_public_sale: "公售時間",
    roadmap: "行程表",
    roadmap_information: { subject_01: "慾望 ModelTV",
                            message_0101: "NFT永久會員、觀影賺取分潤、線下派對，以滿足慾望為起點，將其注入到Web3的世界中。",
                            message_0102: "",
                            message_0103: "",
                            subject_02: "藝術 NFT",
                            message_0201: "1代鑄造、Hime空投，不同文化發展出不同的美感，透過網路加密世界，享受不同的美、滿足不同的慾。",
                            message_0202: "",
                            message_0203: "",
                            subject_03: "科技 Social-Fi",
                            message_0301: "DApps、Smart Contract，在Web3建構一個平台，滿足那些在現實生活中難以被填滿的慾望。",
                            message_0302: "",
                            message_0303: "",
    },
    faq: "問與答",
    faq_information: {  q1 : "公開銷售鑄造的價格是多少？",
                        a1 : "0.1 ETH",
                        q2 : "我一次可以鑄造幾張？",
                        a2 : "一次可以鑄造三張，無限次數。",
                        q3 : "公開銷售共有幾隻Hime？",
                        a3 : "1088隻Hime。",
                        q4 : "什麼是CC0版權？",
                        a4 : "作品的創作者及持有人，都不會擁有作品的版權的所有權。",
                        support : "創作成為公共使用領域的公有財產，代表任何人都可利用該作品於私人及商業用途，二次創作當然也不受限制。"
    },
    team: "團隊",
    team_member: { 
        title_01: "品牌創辦人",
        name_01: "Mr. P",
        title_02: "項目負責人",
        name_02: "Ghost Money",
        title_03: "團隊工程師",
        name_03: "Dicky Chiu",
        title_04: "團隊工程師",
        name_04: "Anson Yu",
        title_05: "藝術家",
        name_05: "Grace",
        title_06: "品牌設計師",
        name_06: "Jin Nine",
        title_07: "品牌設計師",
        name_07: "Lanzhi",
        title_08: "品牌繪師",
        name_08: "Joey",
        title_09: "動態設計師",
        name_09: "Foundi",
        title_10: "概念設計師",
        name_10: "Gold",
        title_11: "營運長",
        name_11: "13",
        title_12: "社群經理",
        name_12: "Danny",
    },
    star_01: {  name: "沖田杏梨",
                message: "日本知名女星、演員、歌手和作曲家 「人間最強BODY」"},
    star_02: {  name: "深田詠美",
                message: "日本的知名女星及YouTuber，被稱為「像韓流偶像般的美女」"},
    star_03: {  name: "Tunamayo",
                message: "為日本知名Coser,有著飽滿的蜜桃尻、緊實蜜腿，ビジネス痴女"},
    star_04: {  name: "小澤瑪莉亞",
                message: "知名女星融合東方和西方的獨特身體特徵，近於西方的面容又有東方的神祕性感"},
}