import styled from 'styled-components';

import ImageFukada from "./../../assets/images/utility/fukada.png";
import ImageMaria from "./../../assets/images/utility/maria.png";
import ImageTunamayo from "./../../assets/images/utility/tunamayo.png";
import ImageOkita from "./../../assets/images/utility/okita.png";
import { useTranslation } from 'react-i18next';

const StyledDiv= styled.div`

  .utility_star_background{
    background-color: black;
    display: flex;
    flex-wrap: wrap;
    padding-top: 5%;
    padding-bottom: 5%;
    justify-content: space-around;

  }

  .utility_star_information{
    padding: 2%;
    color: white;
    max-width: 400px;
    min-width: 300px;
    width: 100%;
    justify-content: space-around;
    font-size: 28px;

    border-width: 0px;
    border-color: white;
    border-style: solid;
    border-radius: 20px;
  }

  .utility_star_information_bg{
    border-width: 4px;
    border-color: white;
    border-style: solid;
    border-radius: 18px;
  }

  .utility_star_information_image{
    padding: 1vw;
    border-radius: 18px;
    img{
      max-width: 450px;
      min-width: 190px;
      width: 100%;
      height: auto;
    }
  }

  .utility_star_information_message{
    width: 100%;

    .utility_star_information_message_bg{
      position: relative;
      text-align: center;
    }
  }

  .utility_star_information_message_name{
    font-size: 28px;
  }

  .utility_star_information_message_message{
    font-size: 20px;
    color: #bbbbbb;
  }

`;

const ComponentsUtility = () => {

    const { t } = useTranslation();

    return (
        <StyledDiv>
          <div id='PAGE_UTILITY'>
            <div className='utility_star_background'>
              <div className='utility_star_information'>
                  <div className='utility_star_information_image' >
                    <img src={ImageFukada}/>
                  </div>
                  <div className='utility_star_information_message'>
                    <div className='utility_star_information_message_bg'>
                      <div className='utility_star_information_message_name'>{ t( "star_02.name" ) }</div>
                      <div className='utility_star_information_message_message'>{ t( "star_02.message" ) }</div>
                    </div>
                  </div>
              </div>
              <div className='utility_star_information'>
                <div className='utility_star_information_image'>
                  <img src={ImageMaria}/>
                </div>
                <div className='utility_star_information_message'>
                  <div className='utility_star_information_message_bg'>
                    <div className='utility_star_information_message_name'>{ t( "star_04.name" ) }</div>
                    <div className='utility_star_information_message_message'>{ t( "star_04.message" ) }</div>
                  </div>
                </div>
              </div>
              <div className='utility_star_information'>
                  <div className='utility_star_information_image'>
                    <img src={ImageTunamayo}/>
                  </div>
                  <div className='utility_star_information_message'>
                    <div className='utility_star_information_message_bg'>
                      <div className='utility_star_information_message_name'>{ t( "star_03.name" ) }</div>
                      <div className='utility_star_information_message_message'>{ t( "star_03.message" ) }</div>
                    </div>
                  </div>
              </div>
              <div className='utility_star_information'>
                <div className='utility_star_information_image'>
                  <img src={ImageOkita}/>
                </div>
                <div className='utility_star_information_message'>
                  <div className='utility_star_information_message_bg'>
                    <div className='utility_star_information_message_name' >{ t( "star_01.name" ) }</div>
                    <div className='utility_star_information_message_message'>{ t( "star_01.message" ) }</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div id='PAGE_UTILITY'>
            <UtilityExtend titleImage={ ImageTitle } />
          </div> */}
        </StyledDiv>
    )
}


export default ComponentsUtility;