import styled from 'styled-components';
import LayoutProduceVideo from '../../../components/LayoutProduceVideo';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const StyledDiv= styled.div`
    .own_extend_gallery{
        padding-top: 30px;
        padding-left: 150px;
        padding-right: 150px;

        @media screen and (max-width: 1400px){
            padding-left: 150px;
            padding-right: 150px;
        }

        @media screen and (max-width: 992px){
            padding-left: 150px;
            padding-right: 150px;
        }

        @media screen and (max-width: 768px){
            padding-left: 50px;
            padding-right: 50px;
        }
        @media screen and (max-width: 576px){
            padding-left: 5px;
            padding-right: 5px;
        }
    }

    .own_extend_info{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        align-content: space-around;
    }

    .own_extend_mevv1{
        position: relative;
        padding-top: 20px;
        padding-left: 20px;
        font-size: 28px;

        @media screen and (max-width: 992px){
            text-align: center;
        }

    }

`;

export const OwnGalleryClass = ( id, isPlay, imageURL, videoURL, link ) => {
    return {
        id : id,
        isPlay : isPlay,
        imageURL : imageURL,
        videoURL : videoURL,
        link : link
    }
}

const OwnExtendGallery = ( { title, gallerys, funcCallBack } ) => {

    const isClickPlay = ( itemID ) => {
        funcCallBack( itemID );
    }

    return (
        <StyledDiv>
            <div className='own_extend_gallery'>
                <div className='own_extend_mevv1'> { title } </div>
                <div className='own_extend_info'>
                    {
                        gallerys.map( ( item ) => {
                            return (
                                <div key={ item.id }>
                                    <div key={ item.id }>
                                        <LayoutProduceVideo
                                            id={ item.id }
                                            isPlay={ item.isPlay }
                                            imageURL={ item.imageURL }
                                            videoURL={ item.videoURL }
                                            funcCallBack={ isClickPlay }
                                            alink={ item.link }
                                        />
                                    </div>
                                </div>
                                
                            )
                        } )
                    }
                </div>
            </div>
        </StyledDiv>
    )
}

export default OwnExtendGallery;