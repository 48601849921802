import styled from 'styled-components';
import $ from 'jquery';
import { useState } from 'react';
//未完成
const StyledDiv= styled.div`
    .layout_produce_video{
        padding: 30px;
        @media screen and (max-width: 768px){
            padding: 5px;
        }
    }
    .layout_produce_info{
        position: relative;
        width: 300px;
        height: 300px;
        text-align: center;

        @media screen and (max-width: 1200px){
            width: 200px;
            height: 200px;
        }

        @media screen and (max-width: 768px){
            width: 250px;
            height: 250px;
        }
    }

    .layout_produce_image{
        position: relative;
        width: 90%;
        height: auto;
        text-align: center;
    }
    
    .layout_produce_image:hover{
        transform: scale( 1.1, 1.1 );
    }

    .layout_produce_btn_play{
        position: absolute;
        width: 40px;
        height: 40px;
        border-radius: 20px;
        border-color: white;
        transform: translate( 250px, -120% );
        background-color: rgba(255,255,255,0.5);
    }

`;

const LayoutProduceVideo = ( { id, imageURL, videoURL, funcCallBack, alink, showControl } ) => {

    const [ isVideo, setIsVideo ] = useState( true );

    const btnPlay = () => {
        setIsVideo( isVideo? false : true );
        funcCallBack( id );
    }

    const getPlayIcon = ( is_play ) => {
        if( is_play )
            return "fas fa-pause"; 
        return "fas fa-play";
    }

    return (
        <StyledDiv>
            <div className='layout_produce_video'>
                <div className='layout_produce_info' >
                    <a href={ alink } target="_blank">
                    { isVideo ? (
                            <video className='layout_produce_image' muted autoPlay="autoplay" loop="loop">
                                <source src={ videoURL } type="video/mp4"/>
                            </video>
                        ):(
                            <img className='layout_produce_image' src={ imageURL }/>
                        )
                    }
                    </a>
                </div>
                { showControl ? (
                        <button className='layout_produce_btn_play' onClick={ btnPlay }> <i className={ getPlayIcon( isVideo ) }></i> </button>
                    ):(
                        <></>
                    )
                }
            </div>
        </StyledDiv>
    )
}

export default LayoutProduceVideo;