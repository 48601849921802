import { contractMEV2Address, contractMEV2ABI, contractMEV2GoerliAddress } from "../configs/contractMEV_2"
import { createContext } from "react";
import { chain } from "wagmi";

const ContextContractMEV2 = createContext( {
    addressOrName: contractMEV2Address,
    contractInterface: contractMEV2ABI,
    chainName: "Mainnet",
    chainID: chain.mainnet.id,
});

export default ContextContractMEV2;