import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { LanguageEN } from "./assets/language/en";
import { LanguageCN } from "./assets/language/zh-cn";
import { LanguageTW } from "./assets/language/zh-tw";

export const LANGUAGE_CN = 'cn';
export const LANGUAGE_TW = 'tw';
export const LANGUAGE_EN = 'en';

const resources = {
    en: { translation: LanguageEN },
    cn: { translation: LanguageCN },
    tw: { translation: LanguageTW },
}

const getDefaultLocale = () => {
    const userLanguage = navigator.language;

    if( ['zh-CN', 'zh-SG'].includes( userLanguage ) ){
        return LANGUAGE_CN;
    }

    if( ['zh-TW', 'zh-HK'].includes(userLanguage) ){
        return LANGUAGE_TW;
    }

    return LANGUAGE_EN;
}

i18n.use(LanguageDetector).use(initReactI18next).init({

    locale: getDefaultLocale(),
    fallbackLocale: "en",
    fallbackLng: "tw",
    // 預設語言
    lng: 'en',//getDefaultLocale(),
    resources,
    interpolation: {
        // 是否要讓字詞 escaped 來防止 xss 攻擊，這裡因為 React.js 已經做了，就設成 false即可
        escapeValue: false,
    },
});

export default i18n;