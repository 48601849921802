import { useContext } from 'react';
import Layout from '../components/Layout';
import ContextContractMEV1 from '../contexts/ContextContractMEV1';
import ContextContractMEV2 from '../contexts/ContextContractMEV2';
import { useTranslation } from 'react-i18next';

const ContractPage = () => {
    
    const contractMEV1 = useContext( ContextContractMEV1 );
    const contractMEV2 = useContext( ContextContractMEV2 );
    const { t } = useTranslation();

    return (
        <Layout>
            <div>This is Contract Page</div>
            <div>MEV v1 in {contractMEV1.chainName} { t( "address" ) } : { contractMEV1.addressOrName }</div>
            <div>MEV v2 in {contractMEV2.chainName} { t( "address" ) } : { contractMEV2.addressOrName }</div>
        </Layout>
    )
}

export default ContractPage;