import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Homepage from './pages/Homepage';
import Aboutpage from './pages/Aboutpage';
import MEV1page from './pages/MEV1page';
import MEV2page from './pages/MEV2page';
import Contractpage from './pages/Contractpage';

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/contract" element={<Contractpage />} />
        <Route path="/mevv1" element={<MEV1page />} />
        <Route path="/mevv2" element={<MEV2page />} />
        <Route path="/about" element={<Aboutpage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;