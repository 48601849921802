import styled from 'styled-components';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import LayoutMintAmount from './../../components/LayoutMintAmount';

import ImageOwnMint from './../../assets/images/own/page_own.png';
import ImageOwnSoldOut from './../../assets/images/own/page_own_sold_out.png';

import ImageOwnerTitle from './../../assets/images/own/title.png'
import ImageOwnNFT0001 from './../../assets/images/own/own_nft_0001.png'
import ImageOwnNFT0002 from './../../assets/images/own/own_nft_0002.png'
import ImageOwnNFT0003 from './../../assets/images/own/own_nft_0003.png'
import ImageOwnNFT0004 from './../../assets/images/own/own_nft_0004.png'
import OwnExtendGallery from './extend/own_gallery_extend';
import { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import VideoDemo0001 from './../../assets/video/video_demo_own_0001.mp4'
import VideoDemo0002 from './../../assets/video/video_demo_own_0002.mp4'
import VideoDemo0003 from './../../assets/video/video_demo_own_0003.mp4'
import VideoDemo0004 from './../../assets/video/video_demo_own_0004.mp4'

import { useContractWrite, useContractRead, useAccount, useConnect } from 'wagmi';
import ContextContractMEV2 from "./../../contexts/ContextContractMEV2";
import {BigNumber, ethers} from "ethers";

const StyledDiv= styled.div`

  .components_own{
    position: relative;
    color: white;
    background-color: black;
  }

  .components_own_title_image{
    position: relative;
    width: 321px;
    left: 12vw;
    padding-top: 2vh;

    img{
      position: relative;
      width: 221px;
      height: 112px;
      z-index: 20;
    }

    div{
      position: relative;
      top: -30px;
      height: 30px;
      width: 321px;
      background: linear-gradient( -35deg, transparent 50px, #444444 0) top right;
      background-repeat: no-repeat;
      z-index: 10;
      @media screen and (max-width: 768px) {
        visibility: hidden;
      }
    }

    @media screen and (max-width: 1400px) {
      left: 5vw;
    }
  
    @media screen and (max-width: 992px) {
      left: 10vw;
    }

    @media screen and (max-width: 768px) {
      left: 0vw;
      width: 100%;
      text-align: center;
    }

  }
  
  .components_own_image{
    position: relative;
    padding-top : 2%;
    padding-bottom: 2%;

    text-align: center;
  
    @media screen and (max-width: 992px) {
      left: 1.5vw;
    }

    img{
      position: relative;
      width: 100%;
      height: auto;
    }

  }

  .layout_mint_amount_operation{
    height: 80px;
    padding: 5px;

    @media screen and (max-width: 992px) {
      width: 100%;
      display: flex;
      justify-content: center;
      text-align: center;
    }

    button{
      width: 50px;
      height: 50px;
      padding: 2px;
      background-color: #444444;
      color: white;
    }

    input{
      padding: 2px;
      width: 50px;
      height: 50px;
      text-align: center;
      background-color: black;
      color: white;
    }
    div{
      padding: 3px;
      font-size: 20px;
    }

  }
  .css_title{
    padding: 2px;
    font-size: 24px;
  }

  .css_button_mint{
    padding: 0px;
    color: white;
    width: 222px;
    height: 50px;
    background-color: #E61773;
    border-width: 0px;
    border-color: white;
    border-radius: 25px;
  }

  .css_button_mint:hover{
    background-color: white;
    color: #E61773;
  }
  .css_button_mint:disabled{
    background-color: #222222;
    color: #bbbbbb;
  }

  .mint_total_supply{
    position: relative;
    width: 222px;
    left: 12vw;
    padding-top: 3%;
    font-size: 46px;

    @media screen and (max-width: 1400px) {
      left: 5vw;
    }
  
    @media screen and (max-width: 992px) {
      left: 0px;
      width: 100%;
      text-align: center;
    }

    @media screen and (max-width: 768px) {
      left: 0vw;
      width: 100%;
      text-align: center;
    }

    div{
      @media screen and (max-width: 992px) {
        text-align: center;
      }
    }
  }
  .mint_operation{
    position: relative;
    left: 12vw;
    width: 222px;
    padding-top: 15%;
    text-align: center;

    @media screen and (max-width: 1400px){
      left: 5vw;
    }

    @media screen and (max-width: 992px) {
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .public_sale_time{
    position: relative;
    display: flex;
    width: 222px;
    justify-content: center;
    @media screen and (max-width: 992px) {
      left: 50%;
      transform: translateX(-50%);
    }
    

    div{
      font-size: 16px;
      width: 55px;
      border-width: 0px;
      border-color: #bbbbbb;
      border-style: solid;
      text-align: center;
    }

    .public_sale_time_items{
      font-size: 30px;
      border-left-width: 2px;
      border-right-width: 2px;
      border-color: #bbbbbb;
      border-left-style: solid;
      border-right-style: solid;
      text-align: center;

    }

  }

  .mint_supply{
    font-size: 38px;
  }

`;

const ALINKMEV1 = "https://opensea.io/zh-TW/collection/model-eros-village-1";

const publicSaleTimeTag = ( { dd, hh, mm, ss } ) => {
  return (
    <div className='public_sale_time' >
      <div>
        <div className="public_sale_time_items" style={ { color: '#E61773' } }>{ dd.toString().padStart( 2, "0" ) }</div>
        <div>Day</div>
      </div>
      <div>
        <div className="public_sale_time_items" style={ { color: '#E61773' } }>{ hh.toString().padStart( 2, "0" ) }</div>
        <div>Hour</div>
      </div>
      <div>
        <div className="public_sale_time_items" style={ { color: '#E61773' } }>{ mm.toString().padStart( 2, "0" ) }</div>
        <div>Min</div>
      </div>
      <div>
        <div className="public_sale_time_items" style={ { color: '#E61773' } }>{ ss.toString().padStart( 2, "0" ) }</div>
        <div>Sec</div>
      </div>
    </div>
  )
}

const ComponentsOWN = ( { funcMint, mintCost, publicSaleMintTime, totalSupply, maxSupply } ) => {

  const { t } = useTranslation();

  const produces = [
    { id: 0, title: "", imageURL: ImageOwnNFT0001 , videoURL: VideoDemo0001, link: ALINKMEV1, information: "金卡 3588", subInformation: "" },
    { id: 1, title: "", imageURL: ImageOwnNFT0002 , videoURL: VideoDemo0002, link: ALINKMEV1, information: "金卡 3641", subInformation: "" },
    { id: 2, title: "", imageURL: ImageOwnNFT0003 , videoURL: VideoDemo0003, link: ALINKMEV1, information: "特卡 2322", subInformation: "" },
    { id: 3, title: "", imageURL: ImageOwnNFT0004 , videoURL: VideoDemo0004, link: ALINKMEV1, information: "特卡 1004", subInformation: "" },
  ];

  const [ produceItems, setProduceItems ] = useState( produces );
  const [ currentTime, setCurrentTime ] = useState();
  const [ mintCurrentCost, setMintCurrentCost ] = useState( "0.1" );
  const [ drawTime, setDrawTime ] = useState( { dd: 0, hh: 0, mm: 0, ss: 0 } );
  const [ isDisabledMint, setIsDisabledMint ] = useState( true );
  const [ isSoldOut, setIsSoldOut ] = useState( false );

  const galleryCallBack = ( itemID ) => {  }

  const mintAmount = ( amount ) => {
    funcMint( amount );
  }

  const funcHandle = ( curNumber ) => {

    let tmpCost;

    if( BigNumber.isBigNumber( mintCost ) )
      tmpCost = mintCost.mul( curNumber );
    else
      tmpCost = mintCost * curNumber;

    setMintCurrentCost( ethers.utils.formatEther( tmpCost ) );
  }

  useEffect( () => {

    let interval = window.setInterval( () => {

      let cur_time = (new Date()).getTime() / 1000;
      let time = publicSaleMintTime - cur_time;
      const dd = parseInt( time / 86400 );
      const hh = parseInt( (time - dd * 86400) / 3600 );
      const mm = parseInt( (time - dd * 86400 - hh * 3600) / 60 );
      const ss = parseInt( time % 60 );

      const num_totalSupply = isNaN( parseInt( totalSupply ) ) ? 0 : parseInt( totalSupply );
      const num_maxSupply = isNaN( parseInt( maxSupply ) ) ? 3888 : parseInt( maxSupply );

      console.log( totalSupply, maxSupply, num_totalSupply, num_maxSupply );

      setCurrentTime( parseInt( cur_time ) );
      if( (num_maxSupply - num_totalSupply) <= 0 ){
        setIsSoldOut( true );
        setIsDisabledMint( true );
        setDrawTime( { dd: 0, hh: 0, mm: 0, ss: 0 } );
      }
      else if( time > 1 )
      {
        setIsDisabledMint( true );
        setDrawTime( { dd: dd, hh: hh, mm: mm, ss: ss } );
      }
      else
      {
        setIsDisabledMint( false );
        setDrawTime( { dd: 0, hh: 0, mm: 0, ss: 0 } );
      }

    }, 1000 );

    return () => {
      clearInterval( interval );
    }

  }, [ currentTime ] );

  return (
    <StyledDiv>
      <div id='PAGE_OWN' style={ {backgroundColor: 'black', color: "white"} }>
        <Row style={ { width: "100%" } }>
          <Col lg sm={0} xl={4}>
            <div className='components_own'>
              <div className='components_own_title_image'>
                <img src={ ImageOwnerTitle }/>
                <div/>
              </div>
              <div className='mint_total_supply'>
                <div>{ t( "own_public_sale" ) }</div>
                <div>{ publicSaleTimeTag( drawTime ) }</div>
                <div className='mint_supply'>{ `${ totalSupply } / ${ maxSupply } `}</div>
              </div>
              <div className='mint_operation'>
                <LayoutMintAmount strTitle={ `Cost : ${ mintCurrentCost } ETH` } 
                                  funcCallBack={ mintAmount }  
                                  funcHandle={ funcHandle }
                                  cssTitle={ "css_title" } 
                                  cssOperation={ "layout_mint_amount_operation" } 
                                  cssBtnMint={ "css_button_mint" } 
                                  isMintDisabled={ isDisabledMint }
                />
              </div>
            </div>
          </Col>
          <Col lg>
            <div className='components_own_image'>
              <img src={ isSoldOut? ImageOwnSoldOut : ImageOwnMint }/>
            </div>
          </Col>
        </Row>
        <OwnExtendGallery 
          // title={ "MEV Gen.1 Gallery" }
          title={""}
          gallerys={ produceItems } 
          funcCallBack={ galleryCallBack } 
        />
      </div>
    </StyledDiv>
  )
}

export default ComponentsOWN;