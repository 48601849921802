import { useContext } from 'react';
import Layout from "../components/Layout";
import ContextContractMEV2 from "../contexts/ContextContractMEV2";
import ContextContractTransferFrom from "../contexts/ContextContractTransferFrom";
import { useContractWrite, useContractRead, useAccount, useNetwork, useContractEvent } from 'wagmi';
import { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import { fs_readFile, fs_writeFile } from '../utils/sys_fs';
import { addressMEVWallet, addressAirDropsByPass } from '../configs/addressMEVWallet';

const _addressAirDropTest = addressMEVWallet;
const _airDropPassAddress = addressAirDropsByPass;

const _address_MEV_wallet = [
    "0x2f02AEe384151e35FE9c427dEF9872Ce2B05eC0D",
    "0xc761b64905bd5b950743c9E6978bFCd019e018fA",
    "0x804F02eD4a31687959C0C0f3081D2d41175CF129",
    "0xa3cd585F8A84F777Fd35A71fB5d5672dEC7cD7A3",
    "0xA5Cf96bE8B3994E9Fc5f8feF6c7f25A3B5d315B0",
    "0x3d6F636640DD5dE4b157f0D2D27E97744F173930",
];

const MEV2page = () => {
    const { data: account } = useAccount();
    const contractMEV2 = useContext( ContextContractMEV2 );
    const contractTransferFrom = useContext( ContextContractTransferFrom );

    const [ airDropPassAddress, setAirDropPassAddress ] = useState( _airDropPassAddress );
    const [ airDropAddress, setAirDropAddress ] = useState( [] );
    const [ maxQuantity, setMaxQuantity ] = useState( '' );
    const [ totalSupply, setTotalSupply ] = useState( '' );
    const [ mintCost, setMintCost ] = useState();
    const [ publicSaleStartTime, setPublicSaleStartTime] = useState( "" );
    const [ publicSaleEndTime, setPublicSaleEndTime] = useState( "" );
    const [ revealTime, setRevealTime] = useState();

    const [ lockList, setLockList] = useState( [] );
    const [ airdropTable, setAirDropTable ] = useState([]);

    const [ transferFromList, setTransferFromList ] = useState( [] );

    const {activeChain, switchNetwork} = useNetwork();

    useEffect(() => {
        try {
            if ( activeChain && activeChain.id !== contractMEV2.chainID ) {
                switchNetwork( contractMEV2.chainID );
            }
        } catch (error) { console.log( error ); }
    }, []);

    const readContractMaxPerMint = useContractRead(
        contractMEV2,
        "maxSupply",
        {
            onSuccess: ( result )=>{ setMaxQuantity( result.toString() ) },
            onError: (error) => {console.log(error.message)},
        }
    );

    const readContractRevealTime = useContractRead(
        contractMEV2,
        "revealTime",
        {
            onSuccess: ( result ) => {
                const time = new Date( result * 1000 );
                setRevealTime( time.toString() );
            },
            onError: (error) => {console.log(error.message)},
        }
    );

    const readContractPublicStartTime = useContractRead(
        contractMEV2,
        "publicSaleStartTime",
        {
            onSuccess: ( result ) => {
                const time = new Date( result * 1000 );
                setPublicSaleStartTime( time.toString() ) ;
                
            },
            onError: (error) => {console.log(error.message)},
        }
    );

    const readContractPublicEndTime = useContractRead(
        contractMEV2,
        "publicSaleEndTime",
        {
            onSuccess : ( result ) => {
                const time = new Date( result * 1000 );
                setPublicSaleEndTime( time.toString() );
            },
            onError: (error) => {console.log(error.message)},
        }
    );

    const writeContractAirDrop = useContractWrite(
        contractMEV2,
        "airdrops",
        {
            onSuccess : ( result ) => { 
                console.log( "writeContractAirDrop : ", result );
            },
            onError : ( error ) => { console.log( "writeContractAirDrop : ", error ) },
        }
    );

    const writeContractMint = useContractWrite(
        contractMEV2,
        "mint",
        {
            onSuccess : ( result ) => { console.log( "writeContractMint Success :", result ) },
            onError : ( error ) => { console.log( "writeContractMint Error :", error ) }
        }
    );

    const writeContractPublicSaleStart = useContractWrite( 
        contractMEV2,
        "setPublicSaleStartTime",
        {
            onSuccess: ( result ) => { console.log( "writeContractPublicSaleStart Success :", result ) },
            onError : ( error ) => { console.log( "writeContractPublicSaleStart Error :", error ) }
        }
    );

    const readContractMintCost = useContractRead(
        contractMEV2,
        "mintCost",
        {
            onSuccess : ( result ) => { setMintCost( ethers.utils.formatEther( result ) ); },
            onError: (error) => {console.log(error.message)},
        }
    );

    const readContractTotalSupply = useContractRead(
        contractMEV2,
        "totalSupply",
        {
            onSuccess : ( result ) => { setTotalSupply( result.toString() ) },
            onError: (error) => {console.log(error.message)},
        } 
    );

    const [ mintNumber, setMintNumber ] = useState();
    const onChangeMintNumber = ( e ) => {
        setMintNumber( e.target.value );
    }

    const btnMintOwner = () => {

        console.log( mintCost, parseInt( mintNumber ) );

        const gas_limit = 230000 * parseInt(mintNumber);

        let tmp_price = mintCost * parseInt(mintNumber);
        const price = ethers.utils.parseEther( tmp_price.toString() );

        writeContractMint.write({
            args : [ mintNumber ],
            overrides: {
                gasLimit: gas_limit,
                //gasPrice: 60000000000,
                value: price,
            }
        });
    }

    const btnDoAirDropMEVWallet = () => {

        const todoAirDrop = [];

        for( let i = 0 ; i < 200 ; i++ ){
            todoAirDrop.push( "0xbD172fbB92920299007A7455b815b5aFB9ea5897" );
        }

        console.log( todoAirDrop );

        writeContractAirDrop.writeAsync({
            args: [ todoAirDrop ],
        });

    }

    const btnDoAirDrop = () => {
        btnDoAirDropMEVWallet();
    }

    const getRandomNumber = () => {
        return parseInt( (Math.random() * 10000) );
    }

    const aipDropLoadTable = ( response ) => {
        // pass ownerOf: "MEV_wallet"
        // { 
        //     ownerOf: response[i].ownerOf,
        //     MEV_1_TokenID: response[i].tokenID,
        //     MEV_2_TokenID: airDropTokenID[ cnt++ ]
        // }

        console.log( response );
        let cnt = 0;
        let doneCheck = 0;
        for( let i = 0 ; i < response.length ; i++ )
        {
            if( response[i].MEV_2_TokenID <= 0 || response[i].MEV_2_TokenID > 2800 ){
                console.log( i, response[i] );
            }else if( response[i].ownerOf === "MEV_wallet"  ){
                cnt += 1;
            }
            doneCheck += 1;
        }
        console.log( "Done : ", cnt, doneCheck );

    }

    const snapshotParseTable = ( response ) => {

        const passAirDrop = _airDropPassAddress;
        const transferFromTables = [];
        const airDropTokenID = [];
        const allOwnOf = [];
        //// create random number tokenID table
        for( let i = 0 ; i < 2800 ; i++ )
        {
            let bRes = true;
            let randomNumber;
            while( 1 )
            {
                bRes = true;
                randomNumber = (getRandomNumber() % 2800) + 1;

                if( 0 < randomNumber && randomNumber <= 2800 )
                {
                    for( let j = 0 ; j < transferFromTables.length ; j++ )
                    {
                        if( transferFromTables[j].MEV_2_TokenID === randomNumber ){
                            bRes = false;
                            break;
                        }
                    }
                }

                if( bRes )
                    break;
            }

            transferFromTables.push( {
                owner: "",
                MEV_1_TokenID: 0,
                MEV_2_TokenID: randomNumber
            } );
        }
        //console.log( transferFromTables );

        /////////// link lock table....
        console.log( lockList );
        for( let i = 0 ; i < lockList.length ; i++ )
        {
            for( let j = 0 ; j < transferFromTables.length ; j++ )
            {
                if( transferFromTables[j].MEV_2_TokenID === lockList[i].tokenID )
                {
                    console.log( j, lockList[i] );
                    transferFromTables[j].owner = lockList[i].address;
                }
            }
        }
        console.log( transferFromTables );

        ///// write user random...
        console.log( response );
        // { ownerOf: "0x0981b70C6a567E9976E320176B00aB9341be0618", tokenID: 1 }
        let cnt = 0;
        for( let i = 0 ; i < response.length ; i++ )
        {

            let bRes;
            bRes = false;
            for( let j = 0 ; j < _airDropPassAddress.length ; j++ )
            {
                if( response[i].ownerOf === _airDropPassAddress[j] )
                {
                    bRes = true;
                    break;
                }
            }

            if( bRes )
                continue;

            //prase table...
            if( transferFromTables[ cnt ].owner !== '' ){
                cnt += 1;
            }

            transferFromTables[ cnt ].owner = response[ i ].ownerOf;
            transferFromTables[ cnt ].MEV_1_TokenID = response[ i ].tokenID;
            cnt += 1;
        }
        // console.log( cnt, transferFromTables );

        //// 補上剩下的址址
        // _address_MEV_wallet
        for( let i = cnt ; i <= 2800 ; i++ )
        {
            let new_owner_idx = ( getRandomNumber() % _address_MEV_wallet.length );
            transferFromTables[ cnt ].owner = _address_MEV_wallet[ new_owner_idx ];
            cnt += 1;
            if( cnt >= 2800 )
                break;
        }

        console.log( cnt, transferFromTables );
        fs_writeFile( "MEV_NFT_2_Airdrops_Table.json", transferFromTables );
    }

    const airDropParse = ( response ) => {
        console.log( "airDrop parse : ", response );
        setAirDropAddress( [] );
        const tmpAddress = [];
        let passCnt = 0;
        for( let i = 0 ; i < response.length ; i++ )
        {
            let isPassAddress = false;
            for( let j = 0 ; j < airDropPassAddress.length ; j++ )
            {
                if( response[i].ownerOf === airDropPassAddress[j] ){
                    passCnt += 1;
                    isPassAddress = true;
                    break;
                }
            }

            if( isPassAddress === false ){
                tmpAddress.push( response[i].ownerOf );
            }
        }

        console.log( "airdrop Address : ", passCnt, tmpAddress );
        setAirDropAddress( tmpAddress );
    }

    const searchSpecialInLoaclFolder = async ( files ) => {
        const special = [];
        for( let i = 0 ; i < 3888 ; i++ )
        {
            let file = files[i];

            let filename = parseInt(file.name.toString());

        //    console.log( filename );
            if( filename < 0 || filename > 2800 )
                continue;

            let response = await fs_readFile( file );
            for( let j = 0 ; j < response.attributes.length ; j++ )
            {
                if( response.attributes[j].trait_type === "Special" ){
                    special.push( { fileName: file.name, name: response.name } );
                }
            }

        }

        console.log( special );

        fs_writeFile( "mev_2_all_special.json", special );
    }

    const checkSnapShotPassWallet = ( file ) => {
        fs_readFile( file ).then( ( response ) => {

            const checkWallet = [];

            for( let i = 0 ; i < _airDropPassAddress.length ; i++ )
            {
                checkWallet.push( {
                    address: _airDropPassAddress[i],
                    cnt : 0
                } );
            }


            for( let i = 0 ; i < response.length ; i++ )
            {
                for( let j = 0 ; j < checkWallet.length ; j++ )
                {
                    if( checkWallet[j].address === response[i].ownerOf ){
                        checkWallet[j].cnt += 1;
                    }
                }
            }

            console.log( checkWallet );

        } );
    }

    const onChangeLoadFile = ( e ) => {
        const file = e.target.files[0];

    //    checkSnapShotPassWallet( file );

    //    searchSpecialInLoaclFolder( e.target.files );

    //     fs_readFile( file ).then( ( response ) => {
    //         //airDropParse( response );

    //         //read test table..
    //     //    aipDropLoadTable( response );
    //     } );
    }

    const btnSetContractSaleTime = () => {
        const time = (new Date()).getTime();
        const cur_time = parseInt(time / 1000);
        console.log( cur_time );
        writeContractPublicSaleStart.write( { 
            args: [ cur_time ],
        } );
    }

    const writeContractSetRevealTime = useContractWrite(
        contractMEV2,
        "setRevealTime",
        {
            onSuccess : ( result ) => { console.log( "writeContractSetRevealTime success : ", result ) },
            onError : ( error ) => { console.log( "writeContractSetRevealTime error : ", error )  },
        } 
    );

    const btnSetRevealTime = () => {
        const time = (new Date()).getTime();
        const cur_time = parseInt(time / 1000);
        console.log( cur_time );
        writeContractSetRevealTime.write( { 
            args: [ cur_time ],
        } );
    }

    const fetchMEV_2_Data = async ( baseURL ) => {

        const special = [];

        for( let j = 1 ; j <= 2800 ; j++ )
        {
            console.log( j );
            let fullPath = baseURL + j.toString() + ".json";
            let response = await fetch( fullPath );
            let metaData = await response.json();
            console.log( metaData.attributes );
            for( let i = 0 ; i < metaData.attributes.length ; i++ )
            {
                if( metaData.attributes[i].trait_type === "Special" ){
                    console.log( fullPath );
                    special.push(fullPath);
                }
            }
        }

        console.log( special );
        fs_writeFile( "mev_2_all_special.json", special );
    }

    const btnSearchSpecial = () => {
        const baseURL = "https://nftstorage.link/ipfs/bafybeihocema66ksjk257hwekvtsbuxjm3thuiad42ureu5j7qxt4tg454/";
        console.log( baseURL );

        fetchMEV_2_Data( baseURL );
    }

    const createRandomTable = ( total ) => {
        const random_table = [];

        for( let i = 0 ; i < total ; i++ )
        {
            let bRes = true;
            let randomNumber;
            while( 1 )
            {
                bRes = true;
                randomNumber = getRandomNumber() % total;

                for( let j = 0 ; j < random_table.length ; j++ )
                {
                    if( random_table[j] === randomNumber ){
                        bRes = false;
                        break;
                    }
                }

                if( bRes )
                    break;
            }

            random_table.push( randomNumber );
        }

        return random_table;
    }

    const onChangeLoadSpecialFile = ( e ) => {
        const file = e.target.files[0];
        fs_readFile( file ).then( (response) => {
            console.log( response );
            const newOwner = _address_MEV_wallet;
            const lock_number = 12;
            const lock_table = [];
            for( let i = 0 ; i < lock_number ; i++ )
            {
                let new_idx = (getRandomNumber() % newOwner.length);
                lock_table.push( {
                    address: newOwner[ new_idx ],
                    tokenID: parseInt( response[i].fileName ),
                } );
            }
            setLockList( lock_table );
            console.log( lock_table );
        } );
    }

    const onChangeLoadSnapShotFile = ( e ) => {
        const file = e.target.files[0];
        fs_readFile( file ).then( ( response ) => {
            snapshotParseTable( response );
        });

    }

    const handleDoCut1400File = () => {
        let table = [];

        for( let i = 0 ; i < (airdropTable.length / 2) ; i++ )
        {
            table.push( airdropTable[ i ] );
            if( table.length >= 200 )
            {
                const fileName = `MEV_2_NFT_Airdrops_${ i + 1 }.json`;
                fs_writeFile( fileName, table );
            //  console.log( fileName, table );
                table = [];
            }
        }

        if( table.length > 0 ){
            fs_writeFile( `MEV_2_NFT_Airdrops_${ airdropTable.length }.json`, table );
        }

        console.log( "Done" );
    }

    const handleDoCut2800File = () => {
        let table = [];

        for( let i = 1400 ; i < airdropTable.length ; i++ )
        {
            table.push( airdropTable[ i ] );
            if( table.length >= 200 )
            {
                const fileName = `MEV_2_NFT_Airdrops_${ i + 1 }.json`;
                fs_writeFile( fileName, table );
            //  console.log( fileName, table );
                table = [];
            }
        }

        if( table.length > 0 ){
            fs_writeFile( `MEV_2_NFT_Airdrops_${ airdropTable.length }.json`, table );
        }

        console.log( "Done" );
    }

    const onChangeLoadMEV2AirDropsTableFile = ( e ) => {
        const file = e.target.files[0];

        fs_readFile( file ).then( ( response ) => {

            const random_idx_table = createRandomTable( response.length );
            const table = [];
            for( let i = 0 ; i < response.length ; i++ )
            {
                let idx = random_idx_table[ i ];
                table.push( response[ idx ] );
            }
            setAirDropTable( table );
        });
    }

    const writeContractSetApprovalForAll = useContractWrite(
        contractMEV2,
        "setApprovalForAll",
        {
            args: [ contractTransferFrom.addressOrName, true ],
        }
    );

    const handleSetApprovalForAll = () => {
        writeContractSetApprovalForAll.write();
    }

    const writeContractTransferFrom = useContractWrite(
        contractTransferFrom,
        "contractTransferFrom",
    );

    const onChangeLoadTransferFromFile = ( e ) => {
        const file = e.target.files[0];
        fs_readFile( file ).then( ( response ) => {
            setTransferFromList( response );
        } );
    }

    const handleTransferFrom = () => {

        const addr = [];
        const tokenID = [];
        if( transferFromList.length > 0 )
        {
            for( let i = 0 ; i < transferFromList.length ; i++ )
            {
                addr.push( transferFromList[i].owner );
                tokenID.push( transferFromList[i].MEV_2_TokenID  );
            }

            console.log( addr, tokenID );
    
            writeContractTransferFrom.write(
                {
                    args : [ "0xb9C9c563e44f3c8E30Da1879f969F576C186A223", addr, tokenID ]
                }
            );
        }
    }

    return (
        <Layout>
            <div>This is MEV-2 page in { contractMEV2?.chainName }</div>
            <div>Address : {contractMEV2?.addressOrName}</div>
            <div>Public Start Time : { publicSaleStartTime }</div>
            <div>Public End Time : { publicSaleEndTime }</div>
            <div>Reveal Time : {revealTime}</div>
            <div>MaxSupply : { maxQuantity }</div>
            <div>
                <div>Mint Cost : { mintCost } ETH</div>
                <button onClick={ btnMintOwner } >Mint</button><input type="number" onChange={ onChangeMintNumber } placeholder="mint number ?"/>
            </div>
            <div>
                {/* <button onClick={ btnSetContractSaleTime }> Set Quick Public Sale Start Time</button> */}
            </div>
            <div>
                {/* <button onClick={ btnSetRevealTime }> Set Quick Reveal Time</button> */}
            </div>
            <div> totalSupply : { totalSupply } </div>
            <button onClick={ btnSearchSpecial }>Search Special</button>
            <button onClick={ btnDoAirDrop }> AirDrop </button>
            <input type="file" accept='.json' onChange={ onChangeLoadFile }/>
            {/* <input type="file" webkitdirectory='true' multiple onChange={ onChangeLoadFile }/> */}
            <div>
                <div> <b> Please Load Special Table : </b><input type="file" accept='.json' placeholder="Special Table ?" onChange={ onChangeLoadSpecialFile }/> </div>
                <div> <b> Please Load Snapshot to Airdrops List table : </b><input type="file" accept='.json' placeholder="Snapshot Table ?" onChange={ onChangeLoadSnapShotFile }/> </div>
                <div> <b> Please Load Airdrops List table to CUT 2 Random in file: </b> <input type="file" accept='.json' placeholder="AirDrop MEV 2 All Table ?" onChange={ onChangeLoadMEV2AirDropsTableFile }/> </div>
                <div> <button onClick={ handleDoCut1400File } >Do Cut 1400</button> <button onClick={ handleDoCut2800File } >Do Cut 2800</button></div>
                <div> <button onClick={ handleSetApprovalForAll }>setApprovalForAll</button> </div>
                <div> <button onClick={ handleTransferFrom }> TransferFrom </button> <input type="file" accept='.json' placeholder="TransferFrom File ?" onChange={ onChangeLoadTransferFromFile }/> </div>
                <p> ### Transfer List ###</p>
                <table border="2" >
                    <thead>
                        <tr>
                            <th> index </th>
                            <th> Owner </th>
                            <th> TokenId </th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        transferFromList?.map( ( item, idx ) => {
                            return ( <tr key={ idx }>
                                <td> { idx } </td>
                                <td> { item.owner } </td>
                                <td> { item.MEV_2_TokenID } </td>
                            </tr> )
                        } )
                    }
                    </tbody>
                </table>
                <p>--- lock ---</p>
                {
                    lockList?.map( (item, idx) => { return <div key={idx} > { item.address + " "} { " TokenID: " + item.tokenID }</div> } )
                }
            </div>
            <div>
                <p> ---  airdrop pass address --- </p>
                {
                    airDropPassAddress?.map( (item, idx) => { return <div key={idx}> { item } </div> } )
                }
            </div>
            <div>
                <i>AirDrops table :</i>
                {
                    airdropTable?.map( ( item, idx) => {
                        return <div key={idx}> { idx } owner: { item.owner } AirDropID: { item.MEV_2_TokenID } </div>
                    } )
                }
            </div>
        </Layout>
    )
}

export default MEV2page;