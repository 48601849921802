export const addressMEVWallet = [
    "0xbD172fbB92920299007A7455b815b5aFB9ea5897", // 陳南
    "0x780B59A39A1B0a78BE423B9ADB6571B06dC2c6FD", // 陳南-1
    "0xd7569965481E62c45aC02B6fB006cAFbF23799b9", // 陳南-2
    "0xB99F96caeB02CFe183292108574179544802F4B1", // 道格
    "0x13BFA4d8A6Ef66ea5aA760adbF95D557d9D7Bd13", // 比德
    "0xa349df3dE24940C813D4a49b7aD02E38A8e84f5D" // 艾利
];

export const addressAirDropsByPass = [
    "0x000000000000000000000000000000000000dEaD", //OpenSea burn hole....
    "0x47AEA02a12e32b4a952d689DEcBdd51b808dAA49",
    "0x2f02AEe384151e35FE9c427dEF9872Ce2B05eC0D",
    "0x9A48CBa0Ae0d41A77BC34a78244c67ee293CbF75",
    "0xc761b64905bd5b950743c9E6978bFCd019e018fA",
    "0x804F02eD4a31687959C0C0f3081D2d41175CF129",
    "0xa3cd585F8A84F777Fd35A71fB5d5672dEC7cD7A3",
    "0xA5Cf96bE8B3994E9Fc5f8feF6c7f25A3B5d315B0",
    "0x3d6F636640DD5dE4b157f0D2D27E97744F173930",
    "0x4EA94AD73f73F1c72f93CB06C2BdDfa488CB05f8",
];

export const addressMEVColdWallet = [
    "0xb9C9c563e44f3c8E30Da1879f969F576C186A223",
];