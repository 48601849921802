import { contractMEV1ABI, contractMEV1MainnetAddress } from "../configs/contractMEV_1"
import { createContext } from "react";
import { chain } from "wagmi";

const ContextContractMEV1 = createContext( {
    addressOrName: contractMEV1MainnetAddress,
    contractInterface: contractMEV1ABI,
    chainName: "mainnet",
    chainID: chain.mainnet.id,
});

export default ContextContractMEV1;