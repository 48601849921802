import styled from 'styled-components';
import ImageLogo from "./../assets/images/logo_background.png";

const StyledDiv= styled.div`
    .layout_title{
        width : 100vw;
        height: auto;
        position: relative;
        z-index: 5;

    }

    .layout_title_image{
        position: relative;
        width: 30vw;
        height: auto;
        background-color: red;
    }

    .layout_title_image_children{
        position: relative;
        background-color: black;
        color: white;
        width: 100vw;
        z-index: 6;
    }

    .layout_title_extend_info{
        position: absolute;
        color: white;
        z-index: 7;
        font-size: 30px;
        width: 50vw;
    }
`;

const LayoutImageTitle = ( { titleURL, children } ) => {

    return (
        <StyledDiv>
            <div>
                <img className='layout_title_image' src={ titleURL } />
                <div className='layout_title_image_children'> { children } </div>
            </div>
        </StyledDiv>
    )
}

export default LayoutImageTitle;