//使用Blob 來寫成 json格式檔案
//@param { string } fileName 檔案名稱
//@param { any } fileData 檔案資料
export function fs_writeFile( fileName, fileData ){
    
    try{
        const jsonData = JSON.stringify( fileData );
        const blob = new Blob( [ jsonData ], { type : "application/json" } );
        const url = URL.createObjectURL( blob );
        const aLink = document.createElement( "a" );
        aLink.download = fileName;
        aLink.href = url;
        aLink.click();

        URL.revokeObjectURL( url );
    } catch ( error ) { 
        console.log( "fs_writeFile : ", error ) 
    }
}

//讀取json格式檔案
//@param fileURL local file
//@return json
export async function fs_readFile( fileURL ){

    try{

        const url = URL.createObjectURL( fileURL );
        const response =  await fetch( url );
        const jsonData = await response.json();
        URL.revokeObjectURL( url );

        return jsonData;
    }catch( error ){
        console.log( "fs_readFile : ", error );
    }

    return null;
}