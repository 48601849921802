import styled from 'styled-components';
import Footer from "./Footer";
import Header from "./Header";
import ImageLayoutGradient from "./../assets/images/gradient.png";

const StyledDiv= styled.div`
    .layout_bg{
        width: 100vw;
        height: 100vh;
        position: fixed;
        z-index: 0;
        background-image: url( ${ ImageLayoutGradient } );
    }
`;

const Layout = ( { children } ) => {
    return (
        <StyledDiv>
            <Header/>
                <div>{ children }</div>
            <Footer/>
        </StyledDiv>
    )
}

export default Layout;