export const LanguageEN =
{

    about: "about",
    mev: "Model Eros Village",
    mev_information: { message_01: "MEV is committed to designing and developing various blockchain technologies, interacting with various products developed by Model Group.",
                        message_02: "And bringing a different color to the blockchain world, with creating a new experience, and leading thousands of supporters of Model in Web2 to join the Web3 world with blockchain step by step, and make the Web3 world vitalitier because of MEV.",
                        message_03: "",
                        message_04: "",
                        message_05: ""},
    hime: "MEV Hime (姬)",
    hime_information: { message_01: "In the renowned Japanese myth “Amano Uzume no Mikoto”, Hime (姬) is a semi-clothed celestial being who embodies and symbolizes the beauty of women. ",
                        message_02: "In the myth, in order to save the world from darkness she performed a special dance called “Tenshin no Mitsu”, to entice Amaterasu with her allure and elegance. ",
                        message_03: "Inspired by the celestial myth, in Model Eros Village. ",
                        message_04: "four Japanese goddesses are determined to bring out our raw, primitive desires; ",
                        message_05: "and save the world from being shrouded in darkness using their femininity and tenderness. ",
                        message_06: "They will be featured in the 3888 NFTs that make up the 2nd generation of MEV’s collectibles." },
    utility: "utility",
    utility_information_01: { subject: "Selection",
                            title_01: "Warrior Mission 1",
                            message_01: "Mint your NFT, you can unlock the permanent Model Media membership via Model APP.",
                            title_02: "Warrior Mission 2",
                            message_02: "NFT holder will unlock the video profit sharing of Model TV.",
                            title_03: "Warrior Mission 3",
                            message_03: "Participating in the mission 2 and Airdrop event to unlock more NFT utilities." },
    utility_information_02: { subject: "for Metaverse",
                            title_01: "Village Mission 1",
                            message_01: "Starting with the Model Media VR World, the NFT holder can provide the idea and script partilly join our porn filming.",
                            title_02: "Village Mission 2",
                            message_02: "Join our 3 days global meetup event with all NFT holders in real life.",
                            title_03: "Village Mission 3",
                            message_03: "Model Media VR World launches and unlock profit sharing function." },
    utility_information_03: { subject: "Eros Village",
                            title_01: "Metaverse Mission",
                            message_01: "You will partially own Model Media Eros Village, join our Village tour, Theater and be a part of Village Community." },
    own_public_sale: "Public Sale",
    roadmap: "roadmap",
    roadmap_information: { subject_01: "Desire",
                            message_0101: "Model TV Life-long membership、Watch to earn、Thai-party.",
                            message_0102: "Fulfill all your desire via Web3.",
                            message_0103: "",
                            subject_02: "Art",
                            message_0201: "Different cultures use art differently to love and show desire.",
                            message_0202: "The Airdrop will make all your wish.",
                            message_0203: "",
                            subject_03: "Technology",
                            message_0301: "DApps, Smart Contract, Social-Fi.",
                            message_0302: "You will find the love in the next stage.",
                            message_0303: "Combine all the excitement in one place.",
    },
    faq: "FAQ",
    faq_information: { q1 : "What is the price of the public sale minting?",
                        a1 : "0.1 ETH",
                        q2 : "How many NFTs could be minted at one time?",
                        a2 : "3 NFTs could be minted at a time, and unlimited times for users.",
                        q3 : "How many NFTs of Hime are on public sale minting?",
                        a3 : "There are 1,088 NFTs of Hime on public sale minting.",
                        q4 : "What is the CC0 copyright?",
                        a4 : "The creator and holder of the work will not own the copyright of the work.",
                        support : "The creation becomes public property in the public domain, which means that anyone could use the copyright for private and commercial purposes, and of course, there is no restriction on secondary creation."
    },
    team: "Team",
    team_member: { 
        title_01: "Founder",
        name_01: "Mr. P",
        title_02: "Co-founder",
        name_02: "Ghost Money",
        title_03: "Engineer",
        name_03: "Dicky Chiu",
        title_04: "Engineer",
        name_04: "Anson Yu",
        title_05: "Artist",
        name_05: "Grace",
        title_06: "Brand Designer",
        name_06: "Jin Nine",
        title_07: "Brand Designer",
        name_07: "Lanzhi",
        title_08: "Brand Designer",
        name_08: "Joey",
        title_09: "Dynamic Designer",
        name_09: "Foundi",
        title_10: "Concept Designer",
        name_10: "Gold",
        title_11: "Chief Operating Officer",
        name_11: "13",
        title_12: "Community Manager",
        name_12: "Danny",
    },
    star_01: {  name: "Anri Okita",
                message: "Japanese actress, singer, and songwriter called \"The world's hottest body\""},
    star_02: {  name: "Eimi Fukada",
                message: "Well-known Japanese actress and YouTuber, known as \"Goddess Beauty.\""},
    star_03: {  name: "Tunamayo",
                message: "Famous Coser in Japan has a peachy butt and thick thighs."},
    star_04: {  name: "Maria Ozawa",
                message: "Actress with a unique blend of Eastern and Western physical characteristics, a current Western appearance, and an Eastern sense of charm."},
}