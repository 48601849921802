import styled from 'styled-components';
import { useAccount, useConnect, useDisconnect, useNetwork, chain } from 'wagmi';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import $ from 'jquery';
import Stack from 'react-bootstrap/Stack';

import ImageLogoMEV from './../assets/images/logo_mev.png';
import ImageIconTwitter from './../assets/images/icon_twitter.png';
import ImageIconDiscord from './../assets/images/icon_discord.png';
import ImageIconInstagram from './../assets/images/icon_instagram.png';
import ImageIconLanguage from './../assets/images/icon_language.png';
import ImageIconOption from './../assets/images/icon_option.png';
import ImageIconOptionExtend from './../assets/images/icon_option_extend.png';
import ImageIconOpenSea from './../assets/images/icon_opensea.png';
import { gsap } from "gsap";

import ImageOptionAbout from './../assets/images/about/option.png';
import ImageOptionUtility from './../assets/images/utility/option.png';
import ImageOptionOwn from './../assets/images/own/option.png';
import ImageOptionRoadMap from './../assets/images/roadmap/option.png';
import ImageOptionFAQ from './../assets/images/FAQ/option.png';
import ImageOptionTeam from './../assets/images/team/option.png';

const StyledHeader = styled.header`

  .nav-main{
    position: relative;
    background-color: black;
    width : 100%;
    height: 80px;
    z-index: 90;
  }

  .nav-logo{
    position: relative;

    @media screen and (max-width: 576px){
      margin-right: 1%; 
    }

    img{
      width: 100%;
      height: auto;
    }
  }

  .nav-info{
    display: flex;
    width : 100%;
    height: 70px;
    margin-left : 40px;
    margin-right : 40px;
    margin-top : 5px;
    margin-bottom : 5px;
    z-index: 80;

    @media screen and (max-width: 576px){
      margin-left: 5px;
      margin-right: 5px;
    }

  }

  .nav-link{
    display: flex;
    width : 460px;
    justify-content: space-around;
    align-items: center; 
  }

  .nav-connect-wallet{
    color: white;
    width: 35%;
    height: 36px;
    background-color: transparent;
    border-width: 3px;
    border-color: white;
    border-radius: 18px;

    @media screen and (max-width: 576px){
      font-size: 14px;
      width: 30vw;
    }

  }

  .nav-bar-items{
    width: 36px;
    height: 36px;
    border: none;
    background-color: transparent;
    background-image: url( ${ ImageIconOption } );
  }

  .nav-bar-language{
    width: 38px;
    height: 120px;
    border-width: 4px;
    border-color: white;
    border-style: solid;
    background-color: transparent;
    position: absolute;
    right : 225px;
    top : 80px;
    z-index: 99;
    text-align: center;
    display: none;
  }

  .nav-bar-language-btn{
    font-size: 14px;
    height: 38px;
    width : auto;
    color: white;
    background-color: transparent;
    border: none;
    left: 0px;
    top: 0px;
    bottom: 0px;
    right: 0px;
    margin: auto;
  }

  .nav-bar-language-btn-action{
    color: black;
    background-color: white;
  }

  .nav-bar-options-info{
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100vh;
    z-index: 70;
    background-color: black;
    display: none;
  }

  .nav-bar-options-info-items{
    position: relative;
    margin-top: 135px;
    margin-left: 38vw;
    margin-right: 38vw;
    z-index: 80;
    text-align: center;

    img{
      height: 12vh;
      width: auto;
      background-color: white;

      @media screen and (max-width: 576px){
        width: 100%;
        height: auto;
      }

    }
    img:hover{
      background-color: #E61773;
    }

    @media screen and (max-width: 1200px){
      margin-left: 10vw;
      margin-right: 10vw;
    }

    @media screen and (max-width: 576px){
      margin-left: 1vw;
      margin-right: 1vw;
    }

  }

  .nav-bar-options-info-items-btn{
    background-color: black;
    border: none;
  }

  .nav-normal-mode{
    position: absolute;
    width : 100%;
    height: 148px;
    display: flex;
    z-index: 999;
  }
  .nav-small-size-mode{
    position: absolute;
    width : 100%;
    height: 148px;
    z-index: 999;
    text-align: center;
  }
  .nav-small-connect-wallet{
    position: relative;
    max-width: 320px;
    min-width: 280px;
    width: 35%;
    height: 36px;
  }

  .nav-small-bar-items{
    position: relative;
    right: 50px;
    width: 36px;
    height: 36px;
    border: none;
    background-color: transparent;
    background-image: url( ${ ImageIconOption } );
  }

  .nav-link-item{
    @media screen and (max-width: 768px){
      display: none;
    }
  }

  .nav-bar-language-btn-options-extend{
    margin: 2px;
    width: 60px;
    height: 44px;
    color: white;
    background-color: transparent;
    border-width: 3px;
    border-color: white;
    border-radius: 18px;
  }

  .nav-bar-language-btn-options-extend-action{
    color: black;
    background-color: white;
  }

  #nav_bar_options_extend{
    display: none;

    @media screen and (max-width: 768px){
      display: inline;
    }
  }

`;

const ALink = ( { name, el, ahref, icon } ) => {
  return (
    <div className='nav-link-item'>
      { el === 'a' ? (
          <a href={ ahref } target="_blank" style={ { textDecoration: "none" } }> <img src={ icon }/> </a>
        ):(
          <button onClick={ ahref } style={{ border: "none", backgroundColor: "transparent" }}> <img src={ icon }/> </button>
        )
      }
    </div>
  );
}

const LANGUAGE_TC = "TC";
const LANGUAGE_SC = "SC";
const LANGUAGE_EN = "EN";

const Header = () => {

    const handleLanguage = () => {
      console.log( "language" );
      $( "#nav_bar_language" ).slideToggle();
    }

    const alinkDatas = [
      { index: 0, el: "a", name: "OpenSea", ahref: 'https://opensea.io/zh-TW/collection/mev-hime', icon : ImageIconOpenSea },
      { index: 1, el: "a", name: "Twitter", ahref: 'https://twitter.com/mev_official', icon : ImageIconTwitter }, 
      { index: 2, el: "a", name: "Discord", ahref: 'https://discord.com/invite/model-mev', icon : ImageIconDiscord },
      { index: 3, el: "a", name: "Instagram", ahref: 'https://www.instagram.com/model_eros_village/', icon : ImageIconInstagram },
      { index: 4, el: "button", name: "Language", ahref: handleLanguage, icon : ImageIconLanguage },
    ];

    const { data: account } = useAccount();
    const { connect, connectors, error, isConnecting, pendingConnector } = useConnect();
    const { disconnect } = useDisconnect();
    const [ strAddress, setStrAddress ] = useState( "Connect Wallet" );
    const [ optionFlag, setOptionFlag ] = useState( false );

    const { activeChain, switchNetwork } = useNetwork();

    const { t, i18n } = useTranslation();
    const [ language, setLanguage ] = useState( LANGUAGE_TC );

    useEffect(() => {
      if ( activeChain && activeChain.id !== chain.mainnet.id ) {

        try{
          if( switchNetwork )
            switchNetwork(chain.mainnet.id);
        }catch ( error ) {
          console.log( error );
        }
        
      }
    }, [activeChain, switchNetwork]);

    useEffect( () => {

      if( account ){
        const strLen = account?.address.length;
        setStrAddress( account?.address.substring( 0, 6 ) + "..." + account?.address.substring( strLen - 4, strLen ) );
      }

    }, [account] );

    useEffect( () => {
      getI18ndefaultLanguage();
    }, []);

    const getI18ndefaultLanguage = () => {
      let oriLanguage;
      if( i18n.language === 'tw' ){

        oriLanguage = LANGUAGE_TC;

      }else if( i18n.language === 'cn' ){

        oriLanguage = LANGUAGE_SC;

      }else{

        oriLanguage = LANGUAGE_EN;

      }

      changeLanguage( oriLanguage );
    }

    const handleChangeLanguage = ( strlanguage ) => {
      $( "#nav_bar_language" ).slideToggle();
      changeLanguage( strlanguage );
    }

    const changeLanguage = ( strlanguage ) => {

      // console.log( "strlanguage : ", strlanguage );
      // console.log( "language : ", language );
      // console.log( "i18nLanguage : ", i18nLanguage );

      let newLanguage;
      let newLanguageExtend;
      let i18nLanguage;

      if( strlanguage === LANGUAGE_TC ){

        newLanguage = $( "#language_tw" );
        newLanguageExtend = $( "#language_tw_extend" );
        i18nLanguage = "tw";

      }else if( strlanguage === LANGUAGE_SC ){

        newLanguage = $( "#language_cn" );
        newLanguageExtend = $( "#language_cn_extend" );
        i18nLanguage = "cn";

      }else{

        newLanguage = $( "#language_en" );
        newLanguageExtend = $( "#language_en_extend" );
        i18nLanguage = "en";

      }

      $( ".nav-bar-language-btn-action" )?.removeClass( "nav-bar-language-btn-action" );
      newLanguage?.addClass( "nav-bar-language-btn-action" );

      $( ".nav-bar-language-btn-options-extend-action" )?.removeClass( "nav-bar-language-btn-options-extend-action" );
      newLanguageExtend?.addClass( "nav-bar-language-btn-options-extend-action" );

      i18n.changeLanguage( i18nLanguage );
      setLanguage( i18n.language );
    }
    

    const btnConnect = () => {
      connect();
    }

    const btnOption = () => {

      if( optionFlag === false ){

        gsap.fromTo( "#nav-bar-options", 
        { left: 1920, onComplete : () => { 
            $( "#nav-bar-options" ).css( { display: 'block' } ); 
          } 
        }, 
        { left: 0 } );

        $( "#nav-bar-btn-option" ).css( 'background-image', 'url(' + ImageIconOptionExtend + ')' );
                    
      }else{

        gsap.fromTo( "#nav-bar-options", 
                    { left: 0 }, 
                    { left: 1920 , onComplete: () => {
                      $( "#nav-bar-options" ).css( { display: 'none' } );
                    } } );

        $( "#nav-bar-btn-option" ).css( 'background-image', 'url(' + ImageIconOption + ')' );
      }
      
      setOptionFlag( !optionFlag );
    }

    const btnOptionGolink = () => {
      $( "#nav-bar-options" ).css( { display: 'none' } );
      $( "#nav-bar-btn-option" ).css( 'background-image', 'url(' + ImageIconOption + ')' );
      setOptionFlag( false );
    }

    const handleConnect = () => {

      if( window.ethereum ){

        connect(connectors[0]);

      }else{
        alert( "Please install MetaMask" );
      }
    }

    return (
      <StyledHeader>
        <div className='nav-main'>
          <div className='nav-normal-mode'>
            <Stack className='nav-info mx-auto' direction="horizontal" gap={2}>
              <div className='nav-logo'><a href='/'><img src={ImageLogoMEV}/></a></div>
              <div className='ms-auto nav-link'>
                  {
                    alinkDatas.map( ( item ) => {
                      return ( <ALink key={ item.index }
                                      el={ item.el }
                                      name={ item.name }
                                      ahref={ item.ahref }
                                      icon={ item.icon }
                                /> 
                      )
                    } )
                  }
                  { account? (
                      <button className='nav-connect-wallet' onClick={ disconnect }> { strAddress } </button>
                    ):(
                      <button className='nav-connect-wallet' onClick={ handleConnect}> Connect </button>
                    )
                  }
                  <button id="nav-bar-btn-option" className='nav-bar-items' onClick={ btnOption }> </button>
              </div>
              <div id="nav_bar_language" className='nav-bar-language'>
                <button id="language_tw" className='nav-bar-language-btn' onClick={ () => { handleChangeLanguage( LANGUAGE_TC ) }}>{ LANGUAGE_TC }</button>
                <button id="language_cn" className='nav-bar-language-btn' onClick={ () => { handleChangeLanguage( LANGUAGE_SC ) }}>{ LANGUAGE_SC }</button>
                <button id="language_en" className='nav-bar-language-btn' onClick={ () => { handleChangeLanguage( LANGUAGE_EN ) }}>{ LANGUAGE_EN }</button>
              </div>
            </Stack>
          </div>
          
          <div id='nav-bar-options' className='nav-bar-options-info'>
            <nav className='nav-bar-options-info-items'>
              <div id="nav_bar_options_extend" style={{ backgroundColor: "black" }}>
                  <div id="nav_bar_options_extend_language">
                    <button id='language_tw_extend' className='nav-bar-language-btn-options-extend' onClick={ () => { changeLanguage( LANGUAGE_TC ) }}>{ LANGUAGE_TC }</button>
                    <button id='language_cn_extend' className='nav-bar-language-btn-options-extend' onClick={ () => { changeLanguage( LANGUAGE_SC ) }}>{ LANGUAGE_SC }</button>
                    <button id='language_en_extend' className='nav-bar-language-btn-options-extend' onClick={ () => { changeLanguage( LANGUAGE_EN ) }}>{ LANGUAGE_EN }</button>
                  </div>
                  <div id="nav_bar_options_extend_alink">
                    <a href={ alinkDatas[0].ahref } target="_blank" style={ { textDecoration: "none" } }> <img style={ { backgroundColor: "black", width: "48px", height: "auto", padding: "2px" } } src={ alinkDatas[0].icon }/></a>
                    <a href={ alinkDatas[1].ahref } target="_blank" style={ { textDecoration: "none" } }> <img style={ { backgroundColor: "black", width: "48px", height: "auto", padding: "2px" } } src={ alinkDatas[1].icon }/></a>
                    <a href={ alinkDatas[2].ahref } target="_blank" style={ { textDecoration: "none" } }> <img style={ { backgroundColor: "black", width: "48px", height: "auto", padding: "2px" } } src={ alinkDatas[2].icon }/></a>
                    <a href={ alinkDatas[3].ahref } target="_blank" style={ { textDecoration: "none" } }> <img style={ { backgroundColor: "black", width: "48px", height: "auto", padding: "2px" } } src={ alinkDatas[3].icon }/></a>
                  </div>
              </div>
              <div><a href='#PAGE_ABOUT'><button className='nav-bar-options-info-items-btn' onClick={btnOptionGolink}><img className='nav-bar-options-info-items-about' src={ ImageOptionAbout }/></button></a></div>
              <div><a href='#PAGE_UTILITY'><button className='nav-bar-options-info-items-btn' onClick={btnOptionGolink}><img className='nav-bar-options-info-items-utility' src={ ImageOptionUtility }/></button></a></div>
              <div><a href='#PAGE_OWN'><button className='nav-bar-options-info-items-btn' onClick={btnOptionGolink}><img className='nav-bar-options-info-items-own' src={ ImageOptionOwn }/></button></a></div>
              <div><a href='#PAGE_ROADMAP'><button className='nav-bar-options-info-items-btn' onClick={btnOptionGolink}><img className='nav-bar-options-info-items-roadmap' src={ ImageOptionRoadMap }/></button></a></div>
              <div><a href='#PAGE_FAQ'><button className='nav-bar-options-info-items-btn' onClick={btnOptionGolink}><img className='nav-bar-options-info-items-faq' src={ ImageOptionFAQ }/></button></a></div>
              <div><a href='#PAGE_TEAM'><button className='nav-bar-options-info-items-btn' onClick={btnOptionGolink}><img className='nav-bar-options-info-items-team' src={ ImageOptionTeam }/></button></a></div>
            </nav>
          </div>
        </div>
      </StyledHeader>
    )
}

export default Header;