import styled from 'styled-components';
import ImageTitle from "./../../assets/images/about/title.png"
import AboutExtend from './extend/about_extend';
import ImageHimeBG from "./../../assets/images/about/hime_background.png"
import ImageLogoMEV from "./../../assets/images/logo_mev.png";
import ImageLogoHime from "./../../assets/images/about/hime_logo.png";
import { useTranslation } from 'react-i18next';

const StyledDiv= styled.div`

  .components_about{
    color: white;
    background-color: black;
  }

  .components_about_title_image{
    position: relative;
    max-width: 424px;
    left: 12vw;

    background-color: black;

    @media screen and (max-width: 1400px) {
      left: 6vw;
    }

    @media screen and (max-width: 992px) {
      left: 10vw;
    }

    @media screen and (max-width: 768px) {
      left: 0vw;
      max-width: 100%;
      text-align: center;
    }

    
    img{
      max-width: 320px;
      position: relative;
      z-index: 20;

      @media screen and (max-width: 360px){
        width: 100%;
        height: auto;
      }
    }

    div{
      position: relative;
      top: -30px;
      height: 30px;
      width: 424px;
      background: linear-gradient( -35deg, transparent 50px, #444444 0) top right;
      background-repeat: no-repeat;
      z-index: 10;

      @media screen and (max-width: 768px) {
        visibility: hidden;
        width: 320px;
      }

    }

  }

  .components_about_mev{
    display: flex;
    position: relative;
    // width: 50%;
    // left: 50%;
    // transform: translateX(-50%);
    width: 80%;
    left: 12vw;
    justify-content: center;
    align-item: center;
    padding-top: 5vh;
    padding-bottom: 5vh;

    @media screen and (max-width: 1400px){
      width: 60%;
    }
    
    @media screen and (max-width: 992px){
      display: block;
      width: 80%;
    }

    .components_about_mev_information{

      div{
        font-size: 16px;
        padding-top: 5px;
      }
      @media screen and (max-width: 576px) {
        height: 70vh;
      }
    }
    .components_about_vline{
      border-width: 2px;
      border-color: white;
      border-right-style: solid;
      margin-left: 3vw;
      margin-right: 3vw;

      @media screen and (max-width: 992px){
        width: 198px;
        border-bottom-style: solid;
        margin-top: 3vh;
        margin-bottom: 3vh;
      }

      @media screen and (max-width: 768px) {
        
        position: relative;
        width: 200px;
        margin-left: 0px;
        margin-right: 0px;
        left: 50%;
        transform: translateX(-50%);

      }

    }
    .components_about_mev_logo{

      @media screen and (max-width: 768px) {
        width: 100%;
        text-align: center;
      }

      img{
        width: 198px;
        height: 88px;
      }

    }

  }

  .components_about_himi{
    position: relative;
  }

  .components_about_himi_bg{
    position: relative;
    img{
      width: 100%;
      height: auto;
      z-index: 1;
    }
    div{
      position: absolute;
      width: 100%;
      height: 100%;
      top: 50%;
      transform: translateY(-50%);
      background: linear-gradient(to bottom, 
                                  rgba(0, 0, 0, 1) 0%, 
                                  rgba(0, 0, 0, 0) 30%, 
                                  rgba(0, 0, 0, 0) 60%, 
                                  rgba(0, 0, 0, 1) 100% );
      z-index: 5;
    }

    @media screen and (max-width: 768px){
      visibility: hidden;
      height: 42vh;
    }

  }

  .components_about_hime_vline{
    position: relative;
    border-width: 2px;
    border-color: white;
    border-right-style: solid;
    margin-left: 3vw;
    margin-right: 3vw;

    @media screen and (max-width: 992px){
      width: 198px;
      border-bottom-style: solid;
      margin-top: 3vh;
      margin-bottom: 3vh;
    }

    @media screen and (max-width: 768px) {
      
      position: relative;
      width: 200px;
      margin-left: 0px;
      margin-right: 0px;
      left: 50%;
      transform: translateX(-50%);

    }

  }

  .components_about_himi_information{
    display: flex;
    position: absolute;
    left: 12vw;
    top: 50%;
    transform: translateY( -50%);
    z-index: 10;

    @media screen and (max-width: 1400px){
      width: 70%;
    }

    @media screen and (max-width: 992px){
      display: block;
      width: 80%;
    }

    @media screen and (max-width: 768px){
      width: 80%;
    }
  }

  .components_about_himi_logo{

    position: relative;

    @media screen and (max-width: 768px){
      text-align: center;
    }
    
    img{
      width: 196px;
      height: auto;
    }

    div{
      width: 200px;
      margin-left: 2px;
      border-width: 2px;
      border-color: white;
      border-bottom-style: solid;

      @media screen and (max-width: 768px){
        position: relative;
        margin-left: 0px;
        margin-right: 0px;
        left: 50%;
        transform: translateX(-50%);
      }

    }



  }

  .components_about_himi_info{
    
    div{
      padding-top: 8px;
    }
    @media screen and (max-width: 576px) {
      height: 70vh;
    }
  }

`;

const ComponentsAbout = () => {

  const { t } = useTranslation();

  return (
    <StyledDiv>
      <div id='PAGE_ABOUT'>
        <div className='components_about'>
          <div className='components_about_title_image'>
            <img src={ ImageTitle }/>
            <div/>
          </div>
          <div className="components_about_mev">
            <div className="components_about_mev_logo">
              <img src={ ImageLogoMEV }/>
            </div>
            <div className="components_about_vline"/>
            <div className='components_about_mev_information'>
              <div>{ t("mev_information.message_01") }</div>
              <div>{ t("mev_information.message_02") }</div>
            </div>
          </div>
          <div className='components_about_himi'>
            <div className='components_about_himi_bg' >
              <img src={ ImageHimeBG }/>
              <div/>
            </div>
            <div className='components_about_himi_information'>
              <div className='components_about_himi_logo'>
                <img src={ ImageLogoHime }/>
                {/* <div/> */}
              </div>
              <div className="components_about_hime_vline"/>
              <div className='components_about_himi_info'>
                <div>{ t("hime_information.message_01") }</div>
                <div>{ t("hime_information.message_02") }</div>
                <div>{ t("hime_information.message_03") }</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </StyledDiv>
  )

}

export default ComponentsAbout;