import { WagmiConfig } from 'wagmi';
import wagmiClinet from './helpers/wagmi.client'
import Router from './Router';
import './App.css';
import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {

  useEffect( () => { AOS.init() }, [] );

  return (
    <WagmiConfig client={ wagmiClinet }>
      <Router />
    </WagmiConfig>
  );
}

export default App;
