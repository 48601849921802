import Layout from '../components/Layout';
import ComponentsMainLogo from './components/ComponentsMainLogo';

import ComponentsAbout from './components/ComponentsAbout';
import ComponentsOWN from './components/ComponentsOwn';
import ComponentsRoadMap from './components/ComponentsRoadMap';
import ComponentsFAQ from './components/ComponentsFAQ';
import ComponentsTeam from './components/ComponentsTeam';
import ComponentsUtility from './components/ComponentsUtility';

import styled from 'styled-components';

import { useContractWrite, useContractRead, useAccount, useConnect, useNetwork, chain } from 'wagmi';
import ContextContractMEV2 from "./../contexts/ContextContractMEV2";
import { useContext, useState, useRef, useEffect } from 'react';
import { BigNumber, ethers } from "ethers";

const StyledDiv= styled.div`

`;

const Homepage = () => {

  const { data: account } = useAccount();
  const { connect, connectors, error, isConnecting, pendingConnector } = useConnect();

  const contractMEV2 = useContext( ContextContractMEV2 );
  const [ totalSupply, setTotalSupply ] = useState("");
  const [ maxSupply, setMaxSupply ] = useState( "" );
  const [ mintCost, setMintCost ] = useState();
  const [ publicSaleStartTime, setPublicSaleStartTime ] = useState( 1667206800 );
  const { activeChain } = useNetwork();

  const readContractTotalSupply = useContractRead(
    contractMEV2,
    "totalSupply",
    {
      onSuccess : ( result ) => { setTotalSupply( result.toString() ) },
      onError: (error) => {console.log(error.message)},
    } 
  );

  const readContractMaxSupply = useContractRead(
    contractMEV2,
    "maxSupply",
    {
        onSuccess : ( result ) => { setMaxSupply( result.toString() ) },
        onError: (error) => {console.log(error.message)},
    } 
  );

  const readContractMintCost = useContractRead(
    contractMEV2,
    "mintCost",
    {
        onSuccess : ( result ) => { setMintCost( result ) },
        onError: (error) => {console.log(error.message)},
    } 
  );

  const readContractPublicStartTime = useContractRead(
    contractMEV2,
    "publicSaleStartTime",
    {
      onSuccess: ( result ) => { setPublicSaleStartTime( result ); },
      onError: (error) => {console.log(error.message)},
    }
  );

  const writeContractMint = useContractWrite(
    contractMEV2,
    "mint",
    {
        onSuccess : ( result ) => { 
          console.log( "writeContractMint Success :", result );
        },

        onError : ( error ) => { 
          console.log( "writeContractMint Error :", error ) ;
          alert( "There is something error, please recheck it." );
        }
    }
  );

  useEffect( () => {

    if( window.ethereum ){

      window.ethereum?.on( 'chainChanged', ( chainID ) => {
        window.location.reload();
      } );

    }
    
  }, [] );

  const intervalRef = useRef();

  async function handleTimer()
  {
    if( window.ethereum )
    {
        const daiAddress = contractMEV2.addressOrName;
        const daiAbi = contractMEV2.contractInterface;
        const provider = new ethers.providers.Web3Provider( window.ethereum );
        const daiContract = new ethers.Contract(daiAddress, daiAbi, provider);

        const res = await daiContract.totalSupply();
        setTotalSupply( res.toString() );
    }
  }

  useEffect( () => {

    let id = window.setInterval( handleTimer, 5000 );
    intervalRef.currentID = id;

    return () => {
      window.clearInterval( id );
    }

  }, [ totalSupply ] );

  const publicMint = ( amount ) => {

    if( account )
    {
      if( activeChain.id !== chain.mainnet.id ){

        alert( "please switch NetWork to Mainnet" );

      }else{

        let gas_limit = 180000 * amount * 1.3;
        let price;

        if( amount === 1 ){

          gas_limit = 200000;

        }else if( amount === 2 ){

          gas_limit = 350000;

        }else if( amount === 3 ){

          gas_limit = 430000;
          
        }
    
        if( BigNumber.isBigNumber( mintCost ) )
          price = mintCost.mul( amount );
        else
          price = mintCost * amount;
    
        writeContractMint.writeAsync( {
          args: [ amount ],
          overrides: {
            gasLimit: gas_limit,
            //gasPrice: 50000000000,
            value: price,
          }
        } );
      }

    }else{

      alert( "please connet MetaMask" );

    }

  }

  return (
    <StyledDiv>
      <Layout>
        <div id='components_main'>
          <ComponentsMainLogo/>
          <ComponentsAbout />
          <ComponentsUtility/>
          <ComponentsOWN  funcMint={ publicMint } 
                          mintCost={ mintCost } 
                          publicSaleMintTime={ publicSaleStartTime } 
                          totalSupply={ totalSupply } 
                          maxSupply={ maxSupply } />
          <ComponentsRoadMap />
          <ComponentsFAQ />
          <ComponentsTeam />
        </div>
      </Layout>
    </StyledDiv>
  )
}

export default Homepage;