import styled from 'styled-components';
import ImageStage01 from "./../../../assets/images/icon_stage01.png";
import ImageStage02 from "./../../../assets/images/icon_stage02.png";
import ImageStage03 from "./../../../assets/images/icon_stage03.png";
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

const StyledDiv= styled.div`
    .roadmap_item{
        margin-top: 50px;
        .roadmap_item_title{
            font-size: 26px;
            color: white;
        }

        @media screen and (max-width: 992px){
            text-align: center;
        }
    }

    .roadmap_item_message{
        font-size: 20px;
        color: #bbbbbb;
        padding-top: 10px;
    }

`;

const statusACTIVE = 0;
const statusPROGRESS = 1;

const RoadMapExtend = () => {

    const { t } = useTranslation();

    const oriRoadMapData = [ 
        { isStatus: statusACTIVE, title: "roadmap_information.subject_01", titleImage: ImageStage01, tMessage01: "roadmap_information.message_0101", tMessage02: "roadmap_information.message_0102", tMessage03: "roadmap_information.message_0103" },
        { isStatus: statusPROGRESS, title: "roadmap_information.subject_02", titleImage: ImageStage02, tMessage01: "roadmap_information.message_0201", tMessage02: "roadmap_information.message_0202", tMessage03: "roadmap_information.message_0203" },
        { isStatus: statusPROGRESS, title: "roadmap_information.subject_03", titleImage: ImageStage03, tMessage01: "roadmap_information.message_0301", tMessage02: "roadmap_information.message_0302", tMessage03: "roadmap_information.message_0303" },
    ];

    const [ roadMapData, setRoadMapData ] = useState( oriRoadMapData );
    
    return (
        <StyledDiv>
            <div>
            {
                roadMapData.map( (item, idx) => {
                    return ( 
                    <div className='roadmap_item' key={ idx }>
                        <div className='roadmap_item_title'>{ t( item.title ) }</div>
                        <div className='roadmap_item_message'>
                            <div>{ t( item.tMessage01 ) }</div>
                            <div>{ t( item.tMessage02 ) }</div>
                            <div>{ t( item.tMessage03 ) }</div>
                        </div>
                    </div> )
                } )
            }
            </div>
        </StyledDiv>
    )
}

export default RoadMapExtend;