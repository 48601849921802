export const LanguageCN =
{
    about: "关于",
    mev: "Model Eros Village",
    mev_information: { message_01: "MEV致力于设计、开发各式区块链技术，与麻豆集团本身所开发的各类产品进行互动，带给区块链世界一份不同的色彩，创造全新的体验，带领麻豆于Web2里的千万名支持者，携手跨进区块链世界，让Web3世界因MEV更加丰富多元。",
                        message_02: "",
                        message_03: "",
                        message_04: "",
                        message_05: ""},
    hime: "MEV Hime (姬)",
    hime_information: { message_01: "Model Eros Village-Hime(姬)是以日本神话中的天钿女命为主旨，由4位知名日本女星为原型，总数为3888的艺术品NFT。",
                        message_02: "天钿女命在神话中以半裸身体体现女性美的舞蹈，并吸引天照大神使世间重现光明之事迹被认为是日本舞蹈的起源。",
                        message_03: "MEV作品中的女星们就像天钿女命那般，以大胆奔放的舞姿和女性柔美的象征去吸引人们最原始的欲望。",
                        message_04: "",
                        message_05: "",
                        message_06: "" },
    utility: "效用",
    utility_information_01: { subject: "拥有你的NFT",
                            title_01: "战士任务 1",
                            message_01: "铸造NFT取得麻豆TV永久会员。",
                            title_02: "战士任务 2",
                            message_02: "持有NFT解锁麻豆TV分润赋能。",
                            title_03: "战士任务 3",
                            message_03: "参与活动空投升级你的NFT。" },
    utility_information_02: { subject: "麻豆元宇宙建构",
                            title_01: "村落任务 1",
                            message_01: "虚拟世界VR计画启动，参与脚本制作与女优征选。",
                            title_02: "村落任务 2",
                            message_02: "带着你的专属NFT参加3天2夜麻豆村全球粉丝见面会。",
                            title_03: "村落任务 3",
                            message_03: "麻豆VR上线，解锁分润与永久会员赋能。" },
    utility_information_03: { subject: "麻豆情趣娱乐村",
                            title_01: "元宇宙任务",
                            message_01: "参与麻豆元宇宙情趣娱乐村、电影院、成人旅游介绍、与社区建设。" },
    own_public_sale: "公售时间",
    roadmap: "行程表",
    roadmap_information: { subject_01: "欲望 ModelTV",
                            message_01: "NFT永久会员、观影赚取分润、线下派对，以满足欲望为起点，将其注入到Web3的世界中。",
                            subject_02: "艺术 NFT",
                            message_02: "1代铸造、Hime空投，不同文化发展出不同的美感，透过网路加密世界，享受不同的美、满足不同的欲。",
                            subject_03: "科技 Social-Fi",
                            message_0: "DApps、Smart Contract，在Web3建构一个平台，满足那些在现实生活中难以被填满的欲望。"
    },
    faq: "问与答",
    faq_information: { q1 : "公开销售铸造的价格是多少？",
                        a1 : "0.1 ETH",
                        q2 : "我一次可以铸造几张？",
                        a2 : "一次可以铸造三张，无限次数。",
                        q3 : "公开销售共有几只Hime？",
                        a3 : "1088只Hime。",
                        q4 : "什么是CC0版权？",
                        a4 : "作品的创作者及持有人，都不会拥有作品的版权的所有权。",
                        support : "创作成为公共使用领域的公有财产，代表任何人都可利用该作品于私人及商业用途，二次创作当然也不受限制。"
    },

    team: "团队",
    team_member: { 
        title_01: "品牌创办人",
        name_01: "Mr. P",
        title_02: "项目负责人",
        name_02: "Ghost Money",
        title_03: "团队工程师",
        name_03: "Dicky Chiu",
        title_04: "团队工程师",
        name_04: "Anson Yu",
        title_05: "艺术家",
        name_05: "Grace",
        title_06: "品牌设计师",
        name_06: "Jin Nine",
        title_07: "品牌设计师",
        name_07: "Lanzhi",
        title_08: "品牌绘师",
        name_08: "Joey",
        title_09: "动态设计师",
        name_09: "Foundi",
        title_10: "概念设计师",
        name_10: "Gold",
        title_11: "营运长",
        name_11: "13",
        title_12: "社群经理",
        name_12: "Danny",
    },
    star_01: {  name: "沖田杏梨",
                message: "日本知名女星、演员、歌手和作曲家 「人间最强BODY」" },
    star_02: {  name: "深田咏美",
                message: "日本的知名女星及YouTuber，被称为「像韩流偶像般的美女」"},
    star_03: {  name: "Tunamayo",
                message: "为日本知名Coser,有着饱满的蜜桃尻、紧实蜜腿，ビジネス痴女"},
    star_04: {  name: "小泽玛莉亚",
                message: "知名女星融合东方和西方的独特身体特征，近于西方的面容又有东方的神秘性感"},
}