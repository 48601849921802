import { useState } from 'react';
import styled from 'styled-components';

const StyledDiv= styled.div`

    .btn_decrement:disabled {
        color: rgba( 0, 0, 0, 1 );
    }

    .btn_increment:disabled{
        color: rgba( 0, 0, 0, 1 );
    }

`;

const LayoutMintAmount = ( { strTitle, extendStr, funcCallBack, funcHandle, cssTitle, cssOperation, cssBtnMint, isMintDisabled } ) => {

    const[ mintAmount, setMintAmount ] = useState( 1 );

    const handleMint = () => {
        funcCallBack( mintAmount );
    }

    const handleDecrement = () => {
        if (mintAmount <= 1){
            return ;
        }
        funcHandle( mintAmount - 1 );
        setMintAmount(mintAmount - 1);
        
    }
    
    const handleIncrement = () => {
        if (mintAmount >= 3){
            return;
        }
        funcHandle( mintAmount + 1 );
        setMintAmount(mintAmount + 1);
    }

    const isDecrementDisabled = () => {
        if( mintAmount <= 1 )
            return true;
        return false;
    }

    const isIncrementDisabled = () => {
        if( mintAmount >= 3 )
            return true;
        return false;
    }

    return (
        <StyledDiv>
            <div>
                <div className={ cssTitle }>{ strTitle }</div>
                <div className={ cssOperation }>
                    <div> { extendStr } </div>
                    <button id="BtnDecrement" className='btn_decrement' onClick={ handleDecrement } disabled={ isDecrementDisabled() }> - </button>
                    <input id="InputNumber" type='text' value={ mintAmount } readOnly/>
                    <button id="BtnIncrement" className='btn_increment' onClick={ handleIncrement } disabled={ isIncrementDisabled() }> + </button>
                </div>
                <button className={ cssBtnMint } onClick={ handleMint } disabled={ isMintDisabled }>MINT</button>
            </div>
        </StyledDiv>

    )

}

export default LayoutMintAmount;