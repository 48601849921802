import { useContext } from 'react';
import Layout from "../components/Layout";
import ContextContractMEV1 from "../contexts/ContextContractMEV1";
import { useContractWrite, useContractRead, useAccount, useNetwork } from 'wagmi';
import { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import { useTranslation } from 'react-i18next';

import { fs_readFile, fs_writeFile } from '../utils/sys_fs';

const MEV1page = () => {
    const { t } = useTranslation();
    const { data: account } = useAccount();
    const contractAddressABI = useContext( ContextContractMEV1 );
    
    const [ approvalForAllAddress, setApprovalForAllAddress ] = useState( '' );
    const [ totalSupply, setTotalSupply ] = useState( '' );
    const [ tokenIDOwner, setTokenIDOwner ] = useState( [] );
    const [ mintCost, setMintCost ] = useState();
    const [ ownerOf, setOwnerOf ] = useState( [] );

    const {activeChain, switchNetwork} = useNetwork();
    useEffect(() => {
        try {
            if ( activeChain && activeChain.id !== contractAddressABI.chainID ) {
                switchNetwork( contractAddressABI.chainID );
            }
        } catch (error) { console.log( error ); }
    }, []);

    const readContractTotalSupply = useContractRead(
        contractAddressABI,
        "totalSupply",
        {
            watch: true,
            onSuccess: ( result ) => { setTotalSupply( result.toString() ); },
        }
    );

    const readContractMintCost = useContractRead(
        contractAddressABI,
        "mintCost",
        {
            watch: true,
            onSuccess : ( result ) => { setMintCost( ethers.utils.formatEther( result ) ); } 
        }
    );

    const writeContractMint = useContractWrite(
        contractAddressABI,
        "mint",
        {
            onSuccess : ( result ) => { console.log( "writeContractMint Success :", result ) },
        }
    );

    const writeContractSetApprovalForAll = useContractWrite(
        contractAddressABI,
        "setApprovalForAll",
    );

    const writeContractTokenIDOwner = useContractWrite( 
        contractAddressABI,
        "ownerOf",
        {
            onSuccess : ( result ) => {
                //console.log( result );
                snapShotList( result.toString() ) 
            }
        }
    );

    const [ mintNumber, setMintNumber ] = useState();
    const onChangeMintNumber = ( e ) => {
        setMintNumber( e.target.value );
    }

    const onChangeSetApprovalForAllAddress = ( e ) => {
        setApprovalForAllAddress( e.target.value );
    }

    const btnMintOwner = () => {

        console.log( mintCost, parseInt( mintNumber ) );

        const gas_limit = 230000 * parseInt(mintNumber);

        let tmp_price = mintCost * parseInt(mintNumber);
        const price = ethers.utils.parseEther( tmp_price.toString() );

        writeContractMint.write({
            args : [ mintNumber ],
            overrides: {
                gasLimit: gas_limit,
                gasPrice: 60000000000,
                value: price,
            }
        });
    }

    const btnApprovalForAllAddress = () => {
        console.log( approvalForAllAddress );
        writeContractSetApprovalForAll.write({
            args : [ approvalForAllAddress, true ],
        });
    }

    const btnSnapshot = async () => {
        setTokenIDOwner( [] );
        setOwnerOf([]);
        for( let i = 0 ; i <= 3888 ; i++ )
        {
            try {
                
                const tokenId = await writeContractTokenIDOwner.writeAsync( { args : [ i ] });
//                console.log( tokenId );
                if( tokenId ){
                    setOwnerOf( ( pre ) => {
                        const preData = [ ...pre ];
                        preData.push( { tokenID : i, ownerOf : tokenId } );
                        return preData;
                    } )
                }

            } catch (error) {
                console.log( error );
            }

        }
    }

    const snapShotList = ( data ) => {
        setTokenIDOwner( ( pre ) => {
            const fillData = [...pre] ;
            fillData.push( data );
//            console.log( fillData );
            return fillData;
        } );
    }

    const btnSaveSnapshot = () => {

        fs_writeFile( "MEV_v1_snapshot.json", ownerOf );

    }

    const onChangeLoadFile = ( e ) => {
    //    console.log( "Load File Start" );
        const file = e.target.files[0];
        fs_readFile( file ).then( ( response ) => {
            console.log( "MEV V1 Snapshot : ", response );
    /*      const ownerOfNumber = [];
            for( let i = 0 ; i < response.length ; i++ )
            {
                let j = 0;
                let isNew;
                isNew = true;
                for( j = 0 ; j < ownerOfNumber.length ; j++ )
                {
                    if( response[i].ownerOf === ownerOfNumber[j].ownerOf ){
                        isNew = false;
                        break;
                    }
                }

                if( isNew ){
                    ownerOfNumber.push( { ownerOf: response[i].ownerOf, cnt: 1 } );
                }else{
                    ownerOfNumber[j].cnt += 1;
                }
            }

            console.log( ownerOfNumber );
            fs_writeFile( "MEV_v1_snapshot_owner_cnt.json", ownerOfNumber );
            */
        } );
    }

    return (
        <Layout>
            <div>This is MEV-1 page in { contractAddressABI?.chainName }</div>
            <div> { t("address") } : {contractAddressABI?.addressOrName}</div>
            <div>
                <div>Mint Cost { mintCost } ETH</div>
                <button onClick={ btnMintOwner } >Mint</button><input type="number" onChange={ onChangeMintNumber } placeholder="mint number ?"/>
            </div>
            <div>
                <button onClick={ btnApprovalForAllAddress }>setApprovalForAll</button>
                <input type="text" onChange={ onChangeSetApprovalForAllAddress } placeholder="address ?"/>
            </div>
            <div> totalSupply : { totalSupply } </div>
            <button onClick={ btnSnapshot }> { t("snapshot") } </button> <button onClick={ btnSaveSnapshot }> Save </button>
            <input type="file" accept='.json' onChange={ onChangeLoadFile }/>
            <div> { t("snapshot") } list count : { tokenIDOwner.length } </div>
            { 
                ownerOf.map( ( item, idx ) => {
                    return (
                        <div key={idx}>
                            { item.tokenID } : { item.ownerOf }
                        </div>
                    )
                })
                /*tokenIDOwner?.map( ( item, idx) => {
                    return (
                        <div key={idx}> { item } </div>
                    )
                }
                )*/
            }
        </Layout>
    )
}

export default MEV1page;